import React from "react";

// 自定义组件
import PrintReport from "./components/PrintReport";


// antd UI组件
import {  Button, Layout, Menu, theme } from 'antd';
import {  UserOutlined } from '@ant-design/icons';

// css
import './Print.css';

const {  Content, Sider } = Layout;


const userItem = [{
  key:'users',
  icon:<UserOutlined/>,
  label:'userList',
  children:[
  {
    key:'zs',
    label:'张三'
  },
  {
    key:'ls',
    label:'李四'
  },
  {
    key:'ww',
    label:'王五'
  },
]
},
{
  key:'addButton',
  label:<Button type="primary"  className="menu-button">新增</Button>
},
{
  key:'changedButton',
  label:<Button type="primary"  className="menu-button">修改</Button>
},
{
  key:'deleteButton',
  label:<Button type="primary"  className="menu-button">删除</Button>
},
{
  key:'printPreviewButton',
  label:<Button type="primary"  className="menu-button">打印预览</Button>
},
]




// 打印功能，路由组件
function Print(){
  const {
    token: { colorBgContainer },
  } = theme.useToken();
    return(
    <Content className="content-style">
        <Layout
          style={{
            padding: '24px 0',
            background: colorBgContainer,
          }}
        >
          <Sider
            style={{
              background: colorBgContainer,
            }}
            width={200}
          >
            <Menu
              mode="inline"
              items={userItem}
            />
            
          </Sider>
          <Content
            style={{
              padding: '0 24px',
              minHeight: 280,
              background:'gray'
            }}>
              <PrintReport></PrintReport>
          </Content>
        </Layout>
      </Content>)
}

export default Print;
