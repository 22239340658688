import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Slider,
  Switch,
  TreeSelect,
  Upload,
  Row,
  Col,
  Divider,
  Space,
  Layout
} from 'antd';


// css
import './PrintReport.css'



function PrintReport(){
 
    return(
  
    <div style={{background:'white',margin:'20px 0 0 0',height:'96%'}}>
    <Row>
        <Col span={4} offset={3}>
          <h1>LOGO</h1>
        </Col >
        <Col span={4} offset={11}>
            <div style={{padding:"20px 20px 0 120px"}}>
              <Space size={28}>
                <Button type="primary">打印</Button>
                <Button type="primary">返回</Button>
              </Space>
            </div>
        </Col>
    </Row>
    <Row>
        <Col span={24} style={{margin:'0 0 0 0'}}>
            <div style={{textAlign:'center'}}>
                <h1><span style={{fontSize:'26px'}}>牙周内窥镜检查报告单</span></h1>
            </div>
        </Col>
    </Row>
    <Row>
      <Col span={6} offset={1}>
        <Input  placeholder='请再此处输入所属医疗机构的名称' 
                bordered={false} 
                size="large" ></Input>
      </Col>
      <Col span={4} offset={12}>
        <div style={{textAlign:'center',padding:'10px 0 0 0'}}>
        <span>报告时间：2023-7-7</span>
        </div>
      </Col>
      <Col span={24}>
          <Divider size='10px' style={{background:"black", margin:"0 0 24px 0"}} />
      </Col>
    </Row>
    <Row>
      <Col span={5} offset={1}>
        姓名：张三
      </Col>
      <Col span={4} offset={1}>
        性别：男
      </Col>
      <Col span={5} offset={2}>
        年龄：30岁
      </Col>
      <Col span={5} offset={1}>
        联系电话：188888888888
      </Col>
    </Row>
    <Row style={{margin:'26px'}}>
      <Col span={24}>
        参考影像：
      </Col>
      <Col span={4} offset={2} style={{paddingTop:'20px'}}>
        <Upload action="/upload.do" listType="picture-card" >
              <div>
                <PlusOutlined />
              </div>
        </Upload>
      </Col>
      <Col span={4} offset={1} style={{paddingTop:'20px'}}>
        
        
        
      </Col>
      <Col span={4}  offset={1} style={{paddingTop:'20px'}}>
        <Upload action="/upload.do" listType="picture-card">
              <div>
                <PlusOutlined />
              </div>
        </Upload>
      </Col>
      <Col span={4}  offset={1} style={{paddingTop:'20px'}}>
        <Upload action="/upload.do" listType="picture-card" style={{height:'150px'}}>
              <div>
                <PlusOutlined />
              </div>
        </Upload>
      </Col>
      <Col span={10}>
        <div style={{textAlign:'center'}}>治疗前</div>
        </Col>
      <Col span={8} offset={3}> 
      <div style={{textAlign:'center'}}>治疗后</div>
      </Col>
    </Row>
    
    </div>
  )
}

export default PrintReport;