import React, { useState, useRef } from 'react';
import './TransparentController.css'; // 导入样式文件

const TransparentController = ({onOpacityChange}) => {

  const inputRef = useRef();
  const [progress, setProgress] = useState(0); // 用于跟踪进度的状态


  const handleMouseDown = () => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    updateProgress(e.clientX);
  };


  const updateProgress = (clientX) => {
    const input = inputRef.current;
    if (input) {
      const rect = input.getBoundingClientRect();
      const offsetX = clientX - rect.left;
      const width = rect.width;
      const percentage = (offsetX / width) * 100;
      setProgress(percentage);
      onOpacityChange(percentage);

    }
  };

  return (
    <>
    <input
        type="range"
        min="0"
        max="100"
        value={progress} // 将进度状态绑定到输入值
        onChange={() => {}} // 设置为空函数，防止控制台报警告
        onMouseDown={handleMouseDown} // 当鼠标按下时开始监听鼠标移动事件
        ref={inputRef} // 将引用绑定到输入元素
        className="TransparentController-bar"
        style={{ width: "90px"}} // 减去按钮的宽度
      />
      
    </>
  );
};

export default TransparentController;
