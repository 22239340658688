import React, { useState, useEffect } from 'react';

const ChristmasTree = () => {
  

  return (
   <div></div>
  );
};

export default ChristmasTree;
