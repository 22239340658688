
import React, { useState } from 'react';
import { Button, Input } from 'antd';

const LoginPage = ({onLogin}) => {

    const [loginError,setLoginError] = useState(false);
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');

    const handleLogin = () => {
        if(username === 'bozhou' && password === 'admin'){
            onLogin()
        }else{
            setLoginError(true);
        }
    };

    //回车触发登陆
    const handleKeyPress = (e) =>{
        if(e.key === 'Enter') {
            handleLogin();
        }
    }


    return(
        <>
        <div  style={{width:'100%',height:'100%'}}>
            <img src='./img/NewYear.png' style={{width:'100%',height:'100%',marginTop:'-190px'}}/>
            <div style={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',marginLeft:'150px'}}>
                <div style={{marginLeft:'160px'}}>
                    <h1>登陆</h1>
                </div>
                <br></br>
                <div style={{width:'650px',rowGap: '1px'}}>
              
                    <div >
                        <label htmlFor='username' style={{ fontWeight: 'bold' }}>用户名：</label>
                        <Input
                        type='text'
                        placeholder='请输入用户名...'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        style={{width:'300px',height:'40px'}}
                        onKeyPress={handleKeyPress}//添加键盘事件
                        />
                    </div>
               
                    <br></br>
                    <div >
                        <label htmlFor='passwodr' style={{ fontWeight: 'bold' }}>密{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}码：</label>
                        <Input
                        type='password'
                        placeholder='请输入密码...'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{width:'300px',height:'40px'}}
                        onKeyPress={handleKeyPress}//添加键盘事件
                        />
                    </div>
                    {loginError && <p style={{color:'red'}}>登陆失败，请输入正确用户名或密码</p>}
                    <br></br>
                    <div style={{marginLeft:'160px'}}>
                        <Button onClick={handleLogin} style={{ fontWeight: 'bold' }}>登陆</Button>
                    </div>
                
                </div>
            
            </div>
        </div>
       
        </>
    )
}
  

export default LoginPage;
