
import React,{useRef,useEffect} from "react";

import '@kitware/vtk.js/Rendering/Profiles/Geometry';

import vtkFullScreenRenderWindow from "@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow";
import vtkCellPicker from "@kitware/vtk.js/Rendering/Core/CellPicker";
import vtkSphereSource from "@kitware/vtk.js/Filters/Sources/SphereSource";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";



// 用于展示读取到的模型
export default function RenderWindows(props){
    const vtkContainerRef = useRef(null);
    const context = useRef(null);

    const {actor} = props;
    useEffect(()=>{
        if (!context.current){
             const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
                 container: vtkContainerRef.current,
            });
            const renderer = fullScreenRenderer.getRenderer();
            const renderWindows = fullScreenRenderer.getRenderWindow();
            
            const render = renderWindows.render;
            const resetCamera = renderer.resetCamera;
            // 拾取
            const picker = vtkCellPicker.newInstance();
            picker.setPickFromList(1);
            picker.setTolerance(0);
            picker.initializePickList();

            context.current={
                renderer,
                renderWindows,
                render,
                resetCamera,
                picker,
            }
        }
    },[vtkContainerRef]);


    useEffect(()=>{
        const {renderer,render,resetCamera,picker} = context.current;
        if(actor!==null){
            console.log("RenderWindows actor",actor);
            renderer.addActor(actor);
            picker.addPickList(actor); // 只拾取actor
            resetCamera();
            render();
        }
    },[actor])

    const cellPicker = ()=>{
        const {renderWindows,renderer,picker} = context.current;
        renderWindows.getInteractor().onRightButtonPress((callDate)=>{
            if(renderer!==callDate.pokedRenderer){
                return;
            }else{
                const sphere = vtkSphereSource.newInstance();
                const sphereMapper = vtkMapper.newInstance({scalarVisibility:false});
                const sphereActor = vtkActor.newInstance();
                sphere.setRadius(0.05);
                const pos = callDate.position;
                const point = [pos.x,pos.y,0.0];
                picker.pick(point,renderer);
                const pickedPoint = picker.getPickPosition();
                sphere.setCenter(pickedPoint);
                sphereMapper.setInputData(sphere.getOutputData());
                sphereActor.setMapper(sphereMapper);
                sphereActor.getProperty().setColor(0.0, 0.0, 0.0);
                renderer.addActor(sphereActor);
                console.log(pickedPoint);
                
            }
                renderWindows.render();
        });
    }

    return(<>
        <div>
            <div ref={vtkContainerRef} style={{ width: '800px', height: '600px' }}
                 onClick={cellPicker}>
            </div>
        </div>
    </>)
}
