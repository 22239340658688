// 在React组件中引入axios库
import axios from 'axios';
import {  Button} from 'antd';
import React, { useEffect, useState} from 'react';

function FolderList() {
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [downloadLinks, setDownloadLinks] = useState([]);

  useEffect(() => {
    // 发送GET请求获取文件夹列表
    axios.get('http://104.168.22.222:3030/folders')
      .then(response => {
        setFolders(response.data);
      })
      .catch(error => {
        console.error('获取文件夹失败:', error);
      });
  }, []);

  const handleFolderClick = (folder) => {
    setSelectedFolder(folder);
     // 发送POST请求将被点击的文件夹数据返回给后端
     axios.post('http://104.168.22.222:3030/selectedFolder', folder)
     .then(response => {
     })
     .catch(error => {
       console.error('返回文件失败:', error);
     });
  };

  useEffect(() => {
    fetch('http://104.168.22.222:3030/other', { mode: 'cors' })
      .then(response => response.json())
      .then(data => {
        const filelower = "lower.stl";
        const fileupper = "upper.stl";
        const filejson = "seedpoint.json"
        const fileLower = data.find(fileLower => fileLower.includes(filelower));
        const fileJson = data.find(fileJson => fileJson.includes(filejson));
        const fileUpper = data.find(fileUpper => fileUpper.includes(fileupper));
        const temp = "http://s499i3u89.hn-bkt.clouddn.com/"+fileLower;
        const temp1 = "http://s499i3u89.hn-bkt.clouddn.com/"+fileJson;
        const temp2 = "http://s499i3u89.hn-bkt.clouddn.com/"+fileUpper;
        // 存储下载链接
        setDownloadLinks([temp,temp1, temp2]);
      })
      .catch(error => {
        console.error('获取下载链接错误:', error);
      });
  }, []);

  const handleSendToBackend = () => {
    // 将 downloadLinks 数据发送给后端
    fetch('http://104.168.22.222:3030/sendDataToBackend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ downloadLinks }),
    })
      .then(response => {
        if (response.ok) {
        } else {
          console.error('数据发送失败');
        }
      })
      .catch(error => {
        console.error('发送数据时出错:', error);
      });
  };

  useEffect(()=>{

  },[]);

  const handleDownload = async () => {
    fetch('http://104.168.22.222:3030/sendDataToBackend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ downloadLinks }),
    })
      .then(response => {
        if (response.ok) {
          return response.blob(); // 获取响应的二进制数据
        }
        throw new Error('网络请求出错');
      })
      .then(data => {
        // 处理后端返回的数据
        let folderName = ''; 
        // 循环内部对 folderName 赋值
        downloadLinks.forEach(link => {
          const commonPart = link.split('/').slice(-2, -1)[0];
          if (!isNaN(commonPart)) {
            folderName = commonPart;
          }
        });
        const responseData = new Blob([data], { type: 'application/zip' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(responseData);
        downloadLink.download = `${folderName}.zip`; // 设置下载文件的名称
        // 将下载链接添加到 DOM 中
        document.body.appendChild(downloadLink);
        // 点击下载链接触发下载
        downloadLink.click();

        // 清理创建的下载链接
        URL.revokeObjectURL(downloadLink.href);
        downloadLink.remove();
      })
      .catch(error => {
        console.error('发生错误:', error);
      });
  };
 

  return (
    <>
    <div>
      <ul style={{overflow: 'auto',height:'150px',backgroundColor:"gaey"}}>
      {folders.map(folder => (
        <li 
        key={folder?.key}
        onClick={() => handleFolderClick(folder)}
        style={{ backgroundColor: selectedFolder === folder ? 'lightblue' : 'transparent' ,}}
        >{folder?.key}
        </li>
        
      ))}
      
      </ul>
      <div style={{paddingTop:'20px',paddingBottom:'20px'}}>
      <Button onClick={handleSendToBackend}>下载</Button>
      <Button onClick={handleDownload}>压缩包</Button>
    </div>
    </div>
    </>
  );
}

export default FolderList;
