// DialogComponent.js
import { collapseClasses } from '@mui/material';
import React from 'react';

import { Button} from 'antd';

const DialogComponent = () => {
  const showNewDialog1 = (y,x) => {
    const newDialog1 = document.createElement('div');

    newDialog1.style.border = '1px solid gray'; // 设置边框
    newDialog1.style.width = '300px';
    newDialog1.style.borderRadius = '10px';
    newDialog1.style.height = '150';
    newDialog1.style.position = 'absolute';
    newDialog1.style.top = `${y}px`;
    newDialog1.style.left = `${x}px`;
    newDialog1.style.backgroundColor = 'fff';
    newDialog1.style.userSelect = 'none';
    newDialog1.style.padding = '0px';
    newDialog1.style.flexDirection = 'column'; // 设置为垂直方向排列
    newDialog1.id = 'newDialog1';
    


    const childDiv = document.createElement('div');
    // 添加文本
    const addText = document.createElement('p');
    addText.textContent = 'Bolton分析';
    addText.style.fontWeight = 'bold'; // 设置加粗
    addText.style.fontSize = '18px'; // 设置字体大
    childDiv.appendChild(addText);
    // 添加关闭图标按钮
    const closeButton1 = document.createElement('img');
    closeButton1.src = './img/icons8-multiply-24.png'; // 设置关闭图标的路径
    closeButton1.style.cursor = 'pointer';
    closeButton1.addEventListener('click', () => {
       hideDialog1();
    });
    childDiv.appendChild(closeButton1);
    
    childDiv.style.backgroundColor = '#ccc'

    childDiv.style.alignItems = 'center';
    childDiv.style.borderTopLeftRadius = '10px';
    childDiv.style.borderTopRightRadius = '10px';
    childDiv.style.justifyContent = 'space-between';
    childDiv.style.display = 'flex';
    childDiv.style.height = '40px'
    childDiv.style.border = '0px solid #ccc';

    newDialog1.appendChild(childDiv);

    const childDiv2 = document.createElement('div');
    

    //前牙
    const AnteriorTooth = document.createElement('p');
    AnteriorTooth.style.marginLeft = '10px'
    AnteriorTooth.textContent = '前牙';
    AnteriorTooth.style.fontWeight = 'bold';
    AnteriorTooth.style.fontSize = '14px';
    AnteriorTooth.style.paddingTop = '30px';
    childDiv2.appendChild(AnteriorTooth);

    const AnteriorTooth1 = document.createElement('p');
    AnteriorTooth1.textContent = '下颌缺陷 0.58mm ';
    AnteriorTooth1.style.fontWeight = 'bold';
    AnteriorTooth1.style.fontSize = '14px';
    AnteriorTooth1.style.marginLeft = '100px'
    AnteriorTooth1.style.paddingTop = '15px';
    childDiv2.appendChild(AnteriorTooth1);

    const hrElement1 = document.createElement('hr');
    newDialog1.appendChild(hrElement1);

    const AnteriorTooth2 = document.createElement('p');
    AnteriorTooth2.textContent = 'Bolton比值 90.7%';
    AnteriorTooth2.style.fontWeight = 'bold';
    AnteriorTooth2.style.fontSize = '14px';
    AnteriorTooth2.style.marginLeft = '100px';
    childDiv2.appendChild(AnteriorTooth2);

    childDiv2.style.backgroundColor = '#fff';
    childDiv2.style.border = '0px solid #fff'
    childDiv2.style.paddingBottom ='20px'

    newDialog1.appendChild(childDiv2);





    const child2Div = document.createElement('div');
    

    //总体
    const Anterior1Tooth = document.createElement('p');
    Anterior1Tooth.style.marginLeft = '10px'
    Anterior1Tooth.textContent = '总体';
    Anterior1Tooth.style.fontWeight = 'bold';
    Anterior1Tooth.style.fontSize = '14px';
    Anterior1Tooth.style.paddingTop = '30px';
    child2Div.appendChild(Anterior1Tooth);

    const Anterior2Tooth = document.createElement('p');
    Anterior2Tooth.textContent = '下颌缺陷 0.01mm ';
    Anterior2Tooth.style.fontWeight = 'bold';
    Anterior2Tooth.style.fontSize = '14px';
    Anterior2Tooth.style.marginLeft = '100px'
    Anterior2Tooth.style.paddingTop = '15px';
    child2Div.appendChild(Anterior2Tooth);

    const hr2Element = document.createElement('hr');
    newDialog1.appendChild(hr2Element);

    const Anterior3Tooth = document.createElement('p');
    Anterior3Tooth.textContent = 'Bolton比值 90.7%';
    Anterior3Tooth.style.fontWeight = 'bold';
    Anterior3Tooth.style.fontSize = '14px';
    Anterior3Tooth.style.marginLeft = '100px';
    child2Div.appendChild(Anterior3Tooth);

    child2Div.style.backgroundColor = '#fff';
    child2Div.style.border = '0px solid #fff'
    child2Div.style.paddingBottom ='20px'

    newDialog1.appendChild(child2Div);

    const hrElement = document.createElement('hr');
    newDialog1.appendChild(hrElement);

    const upder = document.createElement('p');
    upder.textContent = '上右（毫米）\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 下左（毫米）';
    upder.style.fontSize = '12px';
    upder.style.fontWeight = 'bold';
    upder.style.paddingLeft = '40px'
    upder.style.paddingTop = '30px'
    upder.style.backgroundColor = '#fff'
    newDialog1.appendChild(upder);

    //第三div
    const childDiv3 = document.createElement('div');
    childDiv3.style.paddingTop = '10px'
    childDiv3.style.paddingLeft = '30px'
    childDiv3.style.backgroundColor = '#fff'
    childDiv3.style.display = 'flex';  // 设置为flex布局

    const Serial1 = document.createElement('p');
    Serial1.textContent = '11';
    Serial1.style.fontWeight = 'bold';
    Serial1.style.marginRight = '6px';
    Serial1.style.fontSize = '14px';
    Serial1.style.marginLeft = '50px';
    

    const inputElement1 = document.createElement('input');
    inputElement1.type = 'text';
    inputElement1.value = '56.30';
    inputElement1.style.fontWeight = 'bold';
    inputElement1.style.borderRadius = '5px'; // 设置圆角
    inputElement1.style.width = '42px';
    inputElement1.style.height = '18px'
    inputElement1.style.backgroundColor = '#fff';
    

    const button1 = document.createElement('img');
    button1.src = './img/up.png';
    button1.style.width = '14px';
    button1.style.height = '8px';
    button1.style.cursor = 'pointer';
    button1.addEventListener('click', () => {

      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement1.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement1.value = (currentValue + increment).toFixed(2);
    });
    

    const button2 = document.createElement('img');
    button2.src = './img/next.png';
    button2.style.width = '14px';
    button2.style.height = '8px';
    button2.style.cursor = 'pointer';
    button2.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement1.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement1.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
   
    const buttondiv1 = document.createElement('div');
    buttondiv1.style.display = 'flex';  // 设置为flex布局
    buttondiv1.style.flexDirection = 'column';
    buttondiv1.style.paddingTop = '2px'
    buttondiv1.style.gap = '1px'
    buttondiv1.appendChild(button1);
    buttondiv1.appendChild(button2);
    

    const childdiv1 = document.createElement('div');
    childdiv1.style.display = 'flex';  // 设置为flex布局
    childdiv1.style.marginLeft = '-40px'
    childdiv1.style.position = 'relative';
    childdiv1.appendChild(Serial1);
    childdiv1.appendChild(inputElement1)
    childdiv1.appendChild(buttondiv1);

    childDiv3.appendChild(childdiv1);
    
    const Serial2 = document.createElement('p');
    Serial2.textContent = '21';
    Serial2.style.fontWeight = 'bold';
    Serial2.style.marginRight = '6px';
    Serial2.style.fontSize = '14px';
    Serial2.style.marginLeft = '75px';
    

    const inputElement2 = document.createElement('input');
    inputElement2.type = 'text';
    inputElement2.value = '36.30';
    inputElement2.style.fontWeight = 'bold';
    inputElement2.style.borderRadius = '5px'; // 设置圆角
    inputElement2.style.width = '42px';
    inputElement2.style.height = '18px'
    inputElement2.style.backgroundColor = '#fff';
    

    const button3 = document.createElement('img');
    button3.src = './img/up.png';
    button3.style.width = '14px';
    button3.style.height = '8px';
    button3.style.cursor = 'pointer';
    button3.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement2.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement2.value = (currentValue + increment).toFixed(2);
    });
    

    const button4 = document.createElement('img');
    button4.src = './img/next.png';
    button4.style.width = '14px';
    button4.style.height = '8px';
    button4.style.cursor = 'pointer';
    button4.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement2.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement2.value = Math.max((currentValue - increment).toFixed(2), 0);
    });

   
    const buttondiv2 = document.createElement('div');
    buttondiv2.style.display = 'flex';  // 设置为flex布局
    buttondiv2.style.flexDirection = 'column';
    buttondiv2.style.paddingTop = '2px'
    buttondiv2.style.gap = '1px'
    buttondiv2.appendChild(button3);
    buttondiv2.appendChild(button4);
    

    const childdiv2 = document.createElement('div');
    childdiv2.style.display = 'flex';  // 设置为flex布局
    childdiv2.style.marginLeft = '-20px'

    childdiv2.appendChild(Serial2);
    childdiv2.appendChild(inputElement2)
    childdiv2.appendChild(buttondiv2)

    childDiv3.appendChild(childdiv2);

    
    newDialog1.appendChild(childDiv3);






    //第四div
    const childDiv4 = document.createElement('div');
    childDiv4.style.paddingTop = '10px'
    childDiv4.style.paddingLeft = '30px'
    childDiv4.style.backgroundColor = '#fff'
    childDiv4.style.display = 'flex';  // 设置为flex布局

    const Serial3 = document.createElement('p');
    Serial3.textContent = '12';
    Serial3.style.fontWeight = 'bold';
    Serial3.style.marginRight = '6px';
    Serial3.style.fontSize = '14px';
    Serial3.style.marginLeft = '50px';
    

    const inputElement3 = document.createElement('input');
    inputElement3.type = 'text';
    inputElement3.value = '86.30';
    inputElement3.style.fontWeight = 'bold';
    inputElement3.style.borderRadius = '5px'; // 设置圆角
    inputElement3.style.width = '42px';
    inputElement3.style.height = '18px'
    inputElement3.style.backgroundColor = '#fff';
    

    const button5 = document.createElement('img');
    button5.src = './img/up.png';
    button5.style.width = '14px';
    button5.style.height = '8px';
    button5.style.cursor = 'pointer';
    button5.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement3.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement3.value = (currentValue + increment).toFixed(2);
    });
    

    const button6 = document.createElement('img');
    button6.src = './img/next.png';
    button6.style.width = '14px';
    button6.style.height = '8px';
    button6.style.cursor = 'pointer';
    button6.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement3.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement3.value = Math.max((currentValue - increment).toFixed(2), 0);
    });

   
    const buttondiv3 = document.createElement('div');
    buttondiv3.style.display = 'flex';  // 设置为flex布局
    buttondiv3.style.flexDirection = 'column';
    buttondiv3.style.paddingTop = '2px'
    buttondiv3.style.gap = '1px'
    buttondiv3.appendChild(button5);
    buttondiv3.appendChild(button6);
    

    const childdiv3 = document.createElement('div');
    childdiv3.style.display = 'flex';  // 设置为flex布局
    childdiv3.style.marginLeft = '-40px'
    childdiv3.style.position = 'relative';
    childdiv3.appendChild(Serial3);
    childdiv3.appendChild(inputElement3)
    childdiv3.appendChild(buttondiv3);

    childDiv4.appendChild(childdiv3);

    const Serial4 = document.createElement('p');
    Serial4.textContent = '22';
    Serial4.style.fontWeight = 'bold';
    Serial4.style.marginRight = '6px';
    Serial4.style.fontSize = '14px';
    Serial4.style.marginLeft = '75px';
    

    const inputElement4 = document.createElement('input');
    inputElement4.type = 'text';
    inputElement4.value = '59.30';
    inputElement4.style.fontWeight = 'bold';
    inputElement4.style.borderRadius = '5px'; // 设置圆角
    inputElement4.style.width = '42px';
    inputElement4.style.height = '18px'
    inputElement4.style.backgroundColor = '#fff';
    

    const button7 = document.createElement('img');
    button7.src = './img/up.png';
    button7.style.width = '14px';
    button7.style.height = '8px';
    button7.style.cursor = 'pointer';
    button7.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement4.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement4.value = (currentValue + increment).toFixed(2);
    });
    

    const button8 = document.createElement('img');
    button8.src = './img/next.png';
    button8.style.width = '14px';
    button8.style.height = '8px';
    button8.style.cursor = 'pointer';
    button8.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement4.value);
     // 递减值
     const increment = 1.00;
     // 更新输入框的值，并保留一位小数
     inputElement4.value = Math.max((currentValue - increment).toFixed(2), 0);
    });

   
    const buttondiv4 = document.createElement('div');
    buttondiv4.style.display = 'flex';  // 设置为flex布局
    buttondiv4.style.flexDirection = 'column';
    buttondiv4.style.paddingTop = '2px'
    buttondiv4.style.gap = '1px'
    buttondiv4.appendChild(button7);
    buttondiv4.appendChild(button8);
    

    const childdiv4 = document.createElement('div');
    childdiv4.style.display = 'flex';  // 设置为flex布局
    childdiv4.style.marginLeft = '-20px'
    childdiv4.appendChild(Serial4);
    childdiv4.appendChild(inputElement4)
    childdiv4.appendChild(buttondiv4);

    childDiv4.appendChild(childdiv4);
    
    
    newDialog1.appendChild(childDiv4);








    //第五div
    const childDiv5 = document.createElement('div');
    childDiv5.style.paddingTop = '10px'
    childDiv5.style.paddingLeft = '30px'
    childDiv5.style.backgroundColor = '#fff'
    childDiv5.style.display = 'flex';  // 设置为flex布局

    const Serial5 = document.createElement('p');
    Serial5.textContent = '13';
    Serial5.style.fontWeight = 'bold';
    Serial5.style.marginRight = '6px';
    Serial5.style.fontSize = '14px';
    Serial5.style.marginLeft = '50px';
    

    const inputElement5 = document.createElement('input');
    inputElement5.type = 'text';
    inputElement5.value = '88.21';
    inputElement5.style.fontWeight = 'bold';
    inputElement5.style.borderRadius = '5px'; // 设置圆角
    inputElement5.style.width = '42px';
    inputElement5.style.height = '18px'
    inputElement5.style.backgroundColor = '#fff';
    

    const button9 = document.createElement('img');
    button9.src = './img/up.png';
    button9.style.width = '14px';
    button9.style.height = '8px';
    button9.style.cursor = 'pointer';
    button9.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement5.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement5.value = (currentValue + increment).toFixed(2);
    });
    

    const button10 = document.createElement('img');
    button10.src = './img/next.png';
    button10.style.width = '14px';
    button10.style.height = '8px';
    button10.style.cursor = 'pointer';
    button10.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement5.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement5.value = Math.max((currentValue - increment).toFixed(2), 0);
    });

   
    const buttondiv5 = document.createElement('div');
    buttondiv5.style.display = 'flex';  // 设置为flex布局
    buttondiv5.style.flexDirection = 'column';
    buttondiv5.style.paddingTop = '2px'
    buttondiv5.style.gap = '1px'
    buttondiv5.appendChild(button9);
    buttondiv5.appendChild(button10);
    

    const childdiv5 = document.createElement('div');
    childdiv5.style.display = 'flex';  // 设置为flex布局
    childdiv5.style.marginLeft = '-40px'
    childdiv5.style.position = 'relative';
    childdiv5.appendChild(Serial5);
    childdiv5.appendChild(inputElement5)
    childdiv5.appendChild(buttondiv5);

    childDiv5.appendChild(childdiv5);

    const Serial6 = document.createElement('p');
    Serial6.textContent = '22';
    Serial6.style.fontWeight = 'bold';
    Serial6.style.marginRight = '6px';
    Serial6.style.fontSize = '14px';
    Serial6.style.marginLeft = '75px';
    

    const inputElement6 = document.createElement('input');
    inputElement6.type = 'text';
    inputElement6.value = '77.11';
    inputElement6.style.fontWeight = 'bold';
    inputElement6.style.borderRadius = '5px'; // 设置圆角
    inputElement6.style.width = '42px';
    inputElement6.style.height = '18px'
    inputElement6.style.backgroundColor = '#fff';
    

    const button11 = document.createElement('img');
    button11.src = './img/up.png';
    button11.style.width = '14px';
    button11.style.height = '8px';
    button11.style.cursor = 'pointer';
    button11.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement6.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement6.value = (currentValue + increment).toFixed(2);
    });
    

    const button12 = document.createElement('img');
    button12.src = './img/next.png';
    button12.style.width = '14px';
    button12.style.height = '8px';
    button12.style.cursor = 'pointer';
    button12.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement6.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement6.value = Math.max((currentValue - increment).toFixed(2), 0);
    });

   
    const buttondiv6 = document.createElement('div');
    buttondiv6.style.display = 'flex';  // 设置为flex布局
    buttondiv6.style.flexDirection = 'column';
    buttondiv6.style.paddingTop = '2px'
    buttondiv6.style.gap = '1px'
    buttondiv6.appendChild(button11);
    buttondiv6.appendChild(button12);
    

    const childdiv6 = document.createElement('div');
    childdiv6.style.display = 'flex';  // 设置为flex布局
    childdiv6.style.marginLeft = '-20px'
    childdiv6.appendChild(Serial6);
    childdiv6.appendChild(inputElement6)
    childdiv6.appendChild(buttondiv6);

    childDiv5.appendChild(childdiv6);
    
    
    newDialog1.appendChild(childDiv5);





    //第六div
    const childDiv6 = document.createElement('div');
    childDiv6.style.paddingTop = '10px'
    childDiv6.style.paddingLeft = '30px'
    childDiv6.style.backgroundColor = '#fff'
    childDiv6.style.display = 'flex';  // 设置为flex布局

    const Serial7 = document.createElement('p');
    Serial7.textContent = '14';
    Serial7.style.fontWeight = 'bold';
    Serial7.style.marginRight = '6px';
    Serial7.style.fontSize = '14px';
    Serial7.style.marginLeft = '50px';
    

    const inputElement7 = document.createElement('input');
    inputElement7.type = 'text';
    inputElement7.value = '99.23';
    inputElement7.style.fontWeight = 'bold';
    inputElement7.style.borderRadius = '5px'; // 设置圆角
    inputElement7.style.width = '42px';
    inputElement7.style.height = '18px'
    inputElement7.style.backgroundColor = '#fff';
    

    const button13 = document.createElement('img');
    button13.src = './img/up.png';
    button13.style.width = '14px';
    button13.style.height = '8px';
    button13.style.cursor = 'pointer';
    button13.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement7.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement7.value = (currentValue + increment).toFixed(2);
    });
    

    const button14 = document.createElement('img');
    button14.src = './img/next.png';
    button14.style.width = '14px';
    button14.style.height = '8px';
    button14.style.cursor = 'pointer';
    button14.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement7.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement7.value = Math.max((currentValue - increment).toFixed(2), 0);
    });

   
    const buttondiv7 = document.createElement('div');
    buttondiv7.style.display = 'flex';  // 设置为flex布局
    buttondiv7.style.flexDirection = 'column';
    buttondiv7.style.paddingTop = '2px'
    buttondiv7.style.gap = '1px'
    buttondiv7.appendChild(button13);
    buttondiv7.appendChild(button14);
    

    const childdiv7 = document.createElement('div');
    childdiv7.style.display = 'flex';  // 设置为flex布局
    childdiv7.style.marginLeft = '-40px'
    childdiv7.style.position = 'relative';
    childdiv7.appendChild(Serial7);
    childdiv7.appendChild(inputElement7)
    childdiv7.appendChild(buttondiv7);

    childDiv6.appendChild(childdiv7);

    const Serial8 = document.createElement('p');
    Serial8.textContent = '23';
    Serial8.style.fontWeight = 'bold';
    Serial8.style.marginRight = '6px';
    Serial8.style.fontSize = '14px';
    Serial8.style.marginLeft = '75px';
    

    const inputElement8 = document.createElement('input');
    inputElement8.type = 'text';
    inputElement8.value = '65.99';
    inputElement8.style.fontWeight = 'bold';
    inputElement8.style.borderRadius = '5px'; // 设置圆角
    inputElement8.style.width = '42px';
    inputElement8.style.height = '18px'
    inputElement8.style.backgroundColor = '#fff';
    

    const button15 = document.createElement('img');
    button15.src = './img/up.png';
    button15.style.width = '14px';
    button15.style.height = '8px';
    button15.style.cursor = 'pointer';
    button15.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement8.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement8.value = (currentValue + increment).toFixed(2);
    });
    

    const button16 = document.createElement('img');
    button16.src = './img/next.png';
    button16.style.width = '14px';
    button16.style.height = '8px';
    button16.style.cursor = 'pointer';
    button16.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement8.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement8.value = Math.max((currentValue - increment).toFixed(2), 0);
    });

   
    const buttondiv8 = document.createElement('div');
    buttondiv8.style.display = 'flex';  // 设置为flex布局
    buttondiv8.style.flexDirection = 'column';
    buttondiv8.style.paddingTop = '2px'
    buttondiv8.style.gap = '1px'
    buttondiv8.appendChild(button15);
    buttondiv8.appendChild(button16);
    

    const childdiv8 = document.createElement('div');
    childdiv8.style.display = 'flex';  // 设置为flex布局
    childdiv8.style.marginLeft = '-20px'
    childdiv8.appendChild(Serial8);
    childdiv8.appendChild(inputElement8)
    childdiv8.appendChild(buttondiv8);

    childDiv6.appendChild(childdiv8);
    
    
    newDialog1.appendChild(childDiv6);




    //第七div
    const childDiv7 = document.createElement('div');
    childDiv7.style.paddingTop = '10px'
    childDiv7.style.paddingLeft = '30px'
    childDiv7.style.backgroundColor = '#fff'
    childDiv7.style.display = 'flex';  // 设置为flex布局

    const Serial9 = document.createElement('p');
    Serial9.textContent = '15';
    Serial9.style.fontWeight = 'bold';
    Serial9.style.marginRight = '6px';
    Serial9.style.fontSize = '14px';
    Serial9.style.marginLeft = '50px';
    

    const inputElement9 = document.createElement('input');
    inputElement9.type = 'text';
    inputElement9.value = '44.34';
    inputElement9.style.fontWeight = 'bold';
    inputElement9.style.borderRadius = '5px'; // 设置圆角
    inputElement9.style.width = '42px';
    inputElement9.style.height = '18px'
    inputElement9.style.backgroundColor = '#fff';
    

    const button17 = document.createElement('img');
    button17.src = './img/up.png';
    button17.style.width = '14px';
    button17.style.height = '8px';
    button17.style.cursor = 'pointer';
    button17.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement9.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement9.value = (currentValue + increment).toFixed(2);
    });
    

    const button18 = document.createElement('img');
    button18.src = './img/next.png';
    button18.style.width = '14px';
    button18.style.height = '8px';
    button18.style.cursor = 'pointer';
    button18.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement9.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement9.value = Math.max((currentValue - increment).toFixed(2), 0);
    });

   
    const buttondiv9 = document.createElement('div');
    buttondiv9.style.display = 'flex';  // 设置为flex布局
    buttondiv9.style.flexDirection = 'column';
    buttondiv9.style.paddingTop = '2px'
    buttondiv9.style.gap = '1px'
    buttondiv9.appendChild(button17);
    buttondiv9.appendChild(button18);
    

    const childdiv9 = document.createElement('div');
    childdiv9.style.display = 'flex';  // 设置为flex布局
    childdiv9.style.marginLeft = '-40px'
    childdiv9.style.position = 'relative';
    childdiv9.appendChild(Serial9);
    childdiv9.appendChild(inputElement9)
    childdiv9.appendChild(buttondiv9);

    childDiv7.appendChild(childdiv9);

    const Serial10 = document.createElement('p');
    Serial10.textContent = '25';
    Serial10.style.fontWeight = 'bold';
    Serial10.style.marginRight = '6px';
    Serial10.style.fontSize = '14px';
    Serial10.style.marginLeft = '75px';
    

    const inputElement10 = document.createElement('input');
    inputElement10.type = 'text';
    inputElement10.value = '86.00';
    inputElement10.style.fontWeight = 'bold';
    inputElement10.style.borderRadius = '5px'; // 设置圆角
    inputElement10.style.width = '42px';
    inputElement10.style.height = '18px'
    inputElement10.style.backgroundColor = '#fff';
    

    const button19 = document.createElement('img');
    button19.src = './img/up.png';
    button19.style.width = '14px';
    button19.style.height = '8px';
    button19.style.cursor = 'pointer';
    button19.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement10.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement10.value = (currentValue + increment).toFixed(2);
    });
    

    const button20 = document.createElement('img');
    button20.src = './img/next.png';
    button20.style.width = '14px';
    button20.style.height = '8px';
    button20.style.cursor = 'pointer';
    button20.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement10.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement10.value = Math.max((currentValue - increment).toFixed(2), 0);
    });

   
    const buttondiv10 = document.createElement('div');
    buttondiv10.style.display = 'flex';  // 设置为flex布局
    buttondiv10.style.flexDirection = 'column';
    buttondiv10.style.paddingTop = '2px'
    buttondiv10.style.gap = '1px'
    buttondiv10.appendChild(button19);
    buttondiv10.appendChild(button20);
    

    const childdiv10 = document.createElement('div');
    childdiv10.style.display = 'flex';  // 设置为flex布局
    childdiv10.style.marginLeft = '-20px'
    childdiv10.appendChild(Serial10);
    childdiv10.appendChild(inputElement10)
    childdiv10.appendChild(buttondiv10);

    childDiv7.appendChild(childdiv10);
    
    
    newDialog1.appendChild(childDiv7);




     //第八div
     const childDiv8 = document.createElement('div');
     childDiv8.style.paddingTop = '10px'
     childDiv8.style.paddingLeft = '30px'
     childDiv8.style.backgroundColor = '#fff'
     childDiv8.style.display = 'flex';  // 设置为flex布局
 
     const Serial11 = document.createElement('p');
     Serial11.textContent = '16';
     Serial11.style.fontWeight = 'bold';
     Serial11.style.marginRight = '6px';
     Serial11.style.fontSize = '14px';
     Serial11.style.marginLeft = '50px';
     
 
     const inputElement11 = document.createElement('input');
     inputElement11.type = 'text';
     inputElement11.value = '96.80';
     inputElement11.style.fontWeight = 'bold';
     inputElement11.style.borderRadius = '5px'; // 设置圆角
     inputElement11.style.width = '42px';
     inputElement11.style.height = '18px'
     inputElement11.style.backgroundColor = '#fff';
     
 
     const button21 = document.createElement('img');
     button21.src = './img/up.png';
     button21.style.width = '14px';
     button21.style.height = '8px';
     button21.style.cursor = 'pointer';
     button21.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement11.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement11.value = (currentValue + increment).toFixed(2);
    });
     
 
     const button22 = document.createElement('img');
     button22.src = './img/next.png';
     button22.style.width = '14px';
     button22.style.height = '8px';
     button22.style.cursor = 'pointer';
     button22.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement11.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement11.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
 
    
     const buttondiv11 = document.createElement('div');
     buttondiv11.style.display = 'flex';  // 设置为flex布局
     buttondiv11.style.flexDirection = 'column';
     buttondiv11.style.paddingTop = '2px'
     buttondiv11.style.gap = '1px'
     buttondiv11.appendChild(button21);
     buttondiv11.appendChild(button22);
     
 
     const childdiv11 = document.createElement('div');
     childdiv11.style.display = 'flex';  // 设置为flex布局
     childdiv11.style.marginLeft = '-40px'
     childdiv11.style.position = 'relative';
     childdiv11.appendChild(Serial11);
     childdiv11.appendChild(inputElement11)
     childdiv11.appendChild(buttondiv11);
 
     childDiv8.appendChild(childdiv11);
 
     const Serial12 = document.createElement('p');
     Serial12.textContent = '26';
     Serial12.style.fontWeight = 'bold';
     Serial12.style.marginRight = '6px';
     Serial12.style.fontSize = '14px';
     Serial12.style.marginLeft = '75px';
     
 
     const inputElement12 = document.createElement('input');
     inputElement12.type = 'text';
     inputElement12.value = '88.37';
     inputElement12.style.fontWeight = 'bold';
     inputElement12.style.borderRadius = '5px'; // 设置圆角
     inputElement12.style.width = '42px';
     inputElement12.style.height = '18px'
     inputElement12.style.backgroundColor = '#fff';
     
 
     const button23 = document.createElement('img');
     button23.src = './img/up.png';
     button23.style.width = '14px';
     button23.style.height = '8px';
     button23.style.cursor = 'pointer';
     button23.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement12.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement12.value = (currentValue + increment).toFixed(2);
    });
     
 
     const button24 = document.createElement('img');
     button24.src = './img/next.png';
     button24.style.width = '14px';
     button24.style.height = '8px';
     button24.style.cursor = 'pointer';
     button24.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement12.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement12.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
 
    
     const buttondiv12 = document.createElement('div');
     buttondiv12.style.display = 'flex';  // 设置为flex布局
     buttondiv12.style.flexDirection = 'column';
     buttondiv12.style.paddingTop = '2px'
     buttondiv12.style.gap = '1px'
     buttondiv12.appendChild(button23);
     buttondiv12.appendChild(button24);
     
 
     const childdiv12 = document.createElement('div');
     childdiv12.style.display = 'flex';  // 设置为flex布局
     childdiv12.style.marginLeft = '-20px'
     childdiv12.appendChild(Serial12);
     childdiv12.appendChild(inputElement12)
     childdiv12.appendChild(buttondiv12);
 
     childDiv8.appendChild(childdiv12);
     
     
     newDialog1.appendChild(childDiv8);



     const upder1 = document.createElement('p');
     upder1.textContent = '下右（毫米）\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 上左（毫米）';
     upder1.style.fontSize = '12px';
     upder1.style.fontWeight = 'bold';
     upder1.style.paddingLeft = '40px'
     upder1.style.paddingTop = '10px'
     upder1.style.backgroundColor = '#fff'
     newDialog1.appendChild(upder1);


     //第九div
     const childDiv9 = document.createElement('div');
     childDiv9.style.paddingTop = '10px'
     childDiv9.style.paddingLeft = '30px'
     childDiv9.style.backgroundColor = '#fff'
     childDiv9.style.display = 'flex';  // 设置为flex布局
 
     const Serial13 = document.createElement('p');
     Serial13.textContent = '41';
     Serial13.style.fontWeight = 'bold';
     Serial13.style.marginRight = '6px';
     Serial13.style.fontSize = '14px';
     Serial13.style.marginLeft = '50px';
     
 
     const inputElement13 = document.createElement('input');
     inputElement13.type = 'text';
     inputElement13.value = '51.23';
     inputElement13.style.fontWeight = 'bold';
     inputElement13.style.borderRadius = '5px'; // 设置圆角
     inputElement13.style.width = '42px';
     inputElement13.style.height = '18px'
     inputElement13.style.backgroundColor = '#fff';
     
 
     const button25 = document.createElement('img');
     button25.src = './img/up.png';
     button25.style.width = '14px';
     button25.style.height = '8px';
     button25.style.cursor = 'pointer';
     button25.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement13.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement13.value = (currentValue + increment).toFixed(2);
    });
     
 
     const button26 = document.createElement('img');
     button26.src = './img/next.png';
     button26.style.width = '14px';
     button26.style.height = '8px';
     button26.style.cursor = 'pointer';
     button26.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement13.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement13.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
 
    
     const buttondiv13 = document.createElement('div');
     buttondiv13.style.display = 'flex';  // 设置为flex布局
     buttondiv13.style.flexDirection = 'column';
     buttondiv13.style.paddingTop = '2px'
     buttondiv13.style.gap = '1px'
     buttondiv13.appendChild(button25);
     buttondiv13.appendChild(button26);
     
 
     const childdiv13 = document.createElement('div');
     childdiv13.style.display = 'flex';  // 设置为flex布局
     childdiv13.style.marginLeft = '-40px'
     childdiv13.style.position = 'relative';
     childdiv13.appendChild(Serial13);
     childdiv13.appendChild(inputElement13)
     childdiv13.appendChild(buttondiv13);
 
     childDiv9.appendChild(childdiv13);
 
     const Serial14 = document.createElement('p');
     Serial14.textContent = '31';
     Serial14.style.fontWeight = 'bold';
     Serial14.style.marginRight = '6px';
     Serial14.style.fontSize = '14px';
     Serial14.style.marginLeft = '75px';
     
 
     const inputElement14 = document.createElement('input');
     inputElement14.type = 'text';
     inputElement14.value = '76.77';
     inputElement14.style.fontWeight = 'bold';
     inputElement14.style.borderRadius = '5px'; // 设置圆角
     inputElement14.style.width = '42px';
     inputElement14.style.height = '18px'
     inputElement14.style.backgroundColor = '#fff';
     
 
     const button27 = document.createElement('img');
     button27.src = './img/up.png';
     button27.style.width = '14px';
     button27.style.height = '8px';
     button27.style.cursor = 'pointer';
     button27.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement14.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement14.value = (currentValue + increment).toFixed(2);
    });
     
 
     const button28 = document.createElement('img');
     button28.src = './img/next.png';
     button28.style.width = '14px';
     button28.style.height = '8px';
     button28.style.cursor = 'pointer';
     button28.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement14.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement14.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
 
    
     const buttondiv14 = document.createElement('div');
     buttondiv14.style.display = 'flex';  // 设置为flex布局
     buttondiv14.style.flexDirection = 'column';
     buttondiv14.style.paddingTop = '2px'
     buttondiv14.style.gap = '1px'
     buttondiv14.appendChild(button27);
     buttondiv14.appendChild(button28);
     
 
     const childdiv14 = document.createElement('div');
     childdiv14.style.display = 'flex';  // 设置为flex布局
     childdiv14.style.marginLeft = '-20px'
     childdiv14.appendChild(Serial14);
     childdiv14.appendChild(inputElement14)
     childdiv14.appendChild(buttondiv14);
 
     childDiv9.appendChild(childdiv14);
     
     
     newDialog1.appendChild(childDiv9);




     //第十div
     const childDiv10 = document.createElement('div');
     childDiv10.style.paddingTop = '10px'
     childDiv10.style.paddingLeft = '30px'
     childDiv10.style.backgroundColor = '#fff'
     childDiv10.style.display = 'flex';  // 设置为flex布局
 
     const Serial15 = document.createElement('p');
     Serial15.textContent = '42';
     Serial15.style.fontWeight = 'bold';
     Serial15.style.marginRight = '6px';
     Serial15.style.fontSize = '14px';
     Serial15.style.marginLeft = '50px';
     
 
     const inputElement15 = document.createElement('input');
     inputElement15.type = 'text';
     inputElement15.value = '55.41';
     inputElement15.style.fontWeight = 'bold';
     inputElement15.style.borderRadius = '5px'; // 设置圆角
     inputElement15.style.width = '42px';
     inputElement15.style.height = '18px'
     inputElement15.style.backgroundColor = '#fff';
     
 
     const button29 = document.createElement('img');
     button29.src = './img/up.png';
     button29.style.width = '14px';
     button29.style.height = '8px';
     button29.style.cursor = 'pointer';
     button29.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement15.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement15.value = (currentValue + increment).toFixed(2);
    });
     
 
     const button30 = document.createElement('img');
     button30.src = './img/next.png';
     button30.style.width = '14px';
     button30.style.height = '8px';
     button30.style.cursor = 'pointer';
     button30.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement15.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement15.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
 
    
     const buttondiv15 = document.createElement('div');
     buttondiv15.style.display = 'flex';  // 设置为flex布局
     buttondiv15.style.flexDirection = 'column';
     buttondiv15.style.paddingTop = '2px'
     buttondiv15.style.gap = '1px'
     buttondiv15.appendChild(button29);
     buttondiv15.appendChild(button30);
     
 
     const childdiv15 = document.createElement('div');
     childdiv15.style.display = 'flex';  // 设置为flex布局
     childdiv15.style.marginLeft = '-40px'
     childdiv15.style.position = 'relative';
     childdiv15.appendChild(Serial15);
     childdiv15.appendChild(inputElement15)
     childdiv15.appendChild(buttondiv15);
 
     childDiv10.appendChild(childdiv15);
 
     const Serial16 = document.createElement('p');
     Serial16.textContent = '32';
     Serial16.style.fontWeight = 'bold';
     Serial16.style.marginRight = '6px';
     Serial16.style.fontSize = '14px';
     Serial16.style.marginLeft = '75px';
     
 
     const inputElement16 = document.createElement('input');
     inputElement16.type = 'text';
     inputElement16.value = '66.11';
     inputElement16.style.fontWeight = 'bold';
     inputElement16.style.borderRadius = '5px'; // 设置圆角
     inputElement16.style.width = '42px';
     inputElement16.style.height = '18px'
     inputElement16.style.backgroundColor = '#fff';
     
 
     const button31 = document.createElement('img');
     button31.src = './img/up.png';
     button31.style.width = '14px';
     button31.style.height = '8px';
     button31.style.cursor = 'pointer';
     button31.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement16.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement16.value = (currentValue + increment).toFixed(2);
    });
     
 
     const button32 = document.createElement('img');
     button32.src = './img/next.png';
     button32.style.width = '14px';
     button32.style.height = '8px';
     button32.style.cursor = 'pointer';
     button32.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement16.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement16.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
 
    
     const buttondiv16 = document.createElement('div');
     buttondiv16.style.display = 'flex';  // 设置为flex布局
     buttondiv16.style.flexDirection = 'column';
     buttondiv16.style.paddingTop = '2px'
     buttondiv16.style.gap = '1px'
     buttondiv16.appendChild(button31);
     buttondiv16.appendChild(button32);
     
 
     const childdiv16 = document.createElement('div');
     childdiv16.style.display = 'flex';  // 设置为flex布局
     childdiv16.style.marginLeft = '-20px'
     childdiv16.appendChild(Serial16);
     childdiv16.appendChild(inputElement16)
     childdiv16.appendChild(buttondiv16);
 
     childDiv10.appendChild(childdiv16);
     
     
     newDialog1.appendChild(childDiv10);




     //第十一div
     const childDiv11 = document.createElement('div');
     childDiv11.style.paddingTop = '10px'
     childDiv11.style.paddingLeft = '30px'
     childDiv11.style.backgroundColor = '#fff'
     childDiv11.style.display = 'flex';  // 设置为flex布局
 
     const Serial17 = document.createElement('p');
     Serial17.textContent = '43';
     Serial17.style.fontWeight = 'bold';
     Serial17.style.marginRight = '6px';
     Serial17.style.fontSize = '14px';
     Serial17.style.marginLeft = '50px';
     
 
     const inputElement17 = document.createElement('input');
     inputElement17.type = 'text';
     inputElement17.value = '89.33';
     inputElement17.style.fontWeight = 'bold';
     inputElement17.style.borderRadius = '5px'; // 设置圆角
     inputElement17.style.width = '42px';
     inputElement17.style.height = '18px'
     inputElement17.style.backgroundColor = '#fff';
     
 
     const button33 = document.createElement('img');
     button33.src = './img/up.png';
     button33.style.width = '14px';
     button33.style.height = '8px';
     button33.style.cursor = 'pointer';
     button33.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement17.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement17.value = (currentValue + increment).toFixed(2);
    });
     
 
     const button34 = document.createElement('img');
     button34.src = './img/next.png';
     button34.style.width = '14px';
     button34.style.height = '8px';
     button34.style.cursor = 'pointer';
     button34.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement17.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement17.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
 
    
     const buttondiv17 = document.createElement('div');
     buttondiv17.style.display = 'flex';  // 设置为flex布局
     buttondiv17.style.flexDirection = 'column';
     buttondiv17.style.paddingTop = '2px'
     buttondiv17.style.gap = '1px'
     buttondiv17.appendChild(button33);
     buttondiv17.appendChild(button34);
     
 
     const childdiv17 = document.createElement('div');
     childdiv17.style.display = 'flex';  // 设置为flex布局
     childdiv17.style.marginLeft = '-40px'
     childdiv17.style.position = 'relative';
     childdiv17.appendChild(Serial17);
     childdiv17.appendChild(inputElement17)
     childdiv17.appendChild(buttondiv17);
 
     childDiv11.appendChild(childdiv17);
 
     const Serial18 = document.createElement('p');
     Serial18.textContent = '33';
     Serial18.style.fontWeight = 'bold';
     Serial18.style.marginRight = '6px';
     Serial18.style.fontSize = '14px';
     Serial18.style.marginLeft = '75px';
     
 
     const inputElement18 = document.createElement('input');
     inputElement18.type = 'text';
     inputElement18.value = '91.67';
     inputElement18.style.fontWeight = 'bold';
     inputElement18.style.borderRadius = '5px'; // 设置圆角
     inputElement18.style.width = '42px';
     inputElement18.style.height = '18px'
     inputElement18.style.backgroundColor = '#fff';
     
 
     const button35 = document.createElement('img');
     button35.src = './img/up.png';
     button35.style.width = '14px';
     button35.style.height = '8px';
     button35.style.cursor = 'pointer';
     button35.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement18.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement18.value = (currentValue + increment).toFixed(2);
    });
 
     const button36 = document.createElement('img');
     button36.src = './img/next.png';
     button36.style.width = '14px';
     button36.style.height = '8px';
     button36.style.cursor = 'pointer';
     button36.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement18.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement18.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
 
    
     const buttondiv18 = document.createElement('div');
     buttondiv18.style.display = 'flex';  // 设置为flex布局
     buttondiv18.style.flexDirection = 'column';
     buttondiv18.style.paddingTop = '2px'
     buttondiv18.style.gap = '1px'
     buttondiv18.appendChild(button35);
     buttondiv18.appendChild(button36);
     
 
     const childdiv18 = document.createElement('div');
     childdiv18.style.display = 'flex';  // 设置为flex布局
     childdiv18.style.marginLeft = '-20px'
     childdiv18.appendChild(Serial18);
     childdiv18.appendChild(inputElement18)
     childdiv18.appendChild(buttondiv18);
 
     childDiv11.appendChild(childdiv18);
     
     
     newDialog1.appendChild(childDiv11);





     //第十二div
     const childDiv12 = document.createElement('div');
     childDiv12.style.paddingTop = '10px'
     childDiv12.style.paddingLeft = '30px'
     childDiv12.style.backgroundColor = '#fff'
     childDiv12.style.display = 'flex';  // 设置为flex布局
 
     const Serial19 = document.createElement('p');
     Serial19.textContent = '44';
     Serial19.style.fontWeight = 'bold';
     Serial19.style.marginRight = '6px';
     Serial19.style.fontSize = '14px';
     Serial19.style.marginLeft = '50px';
     
 
     const inputElement19 = document.createElement('input');
     inputElement19.type = 'text';
     inputElement19.value = '44.87';
     inputElement19.style.fontWeight = 'bold';
     inputElement19.style.borderRadius = '5px'; // 设置圆角
     inputElement19.style.width = '42px';
     inputElement19.style.height = '18px'
     inputElement19.style.backgroundColor = '#fff';
     
 
     const button37 = document.createElement('img');
     button37.src = './img/up.png';
     button37.style.width = '14px';
     button37.style.height = '8px';
     button37.style.cursor = 'pointer';
     button37.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement19.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement19.value = (currentValue + increment).toFixed(2);
    });
     
 
     const button38 = document.createElement('img');
     button38.src = './img/next.png';
     button38.style.width = '14px';
     button38.style.height = '8px';
     button38.style.cursor = 'pointer';
     button38.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement19.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement19.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
 
    
     const buttondiv19 = document.createElement('div');
     buttondiv19.style.display = 'flex';  // 设置为flex布局
     buttondiv19.style.flexDirection = 'column';
     buttondiv19.style.paddingTop = '2px'
     buttondiv19.style.gap = '2px'
     buttondiv19.appendChild(button37);
     buttondiv19.appendChild(button38);
     
 
     const childdiv19 = document.createElement('div');
     childdiv19.style.display = 'flex';  // 设置为flex布局
     childdiv19.style.marginLeft = '-40px'
     childdiv19.style.position = 'relative';
     childdiv19.appendChild(Serial19);
     childdiv19.appendChild(inputElement19)
     childdiv19.appendChild(buttondiv19);
 
     childDiv12.appendChild(childdiv19);
 
     const Serial20 = document.createElement('p');
     Serial20.textContent = '34';
     Serial20.style.fontWeight = 'bold';
     Serial20.style.marginRight = '6px';
     Serial20.style.fontSize = '14px';
     Serial20.style.marginLeft = '75px';
     
 
     const inputElement20 = document.createElement('input');
     inputElement20.type = 'text';
     inputElement20.value = '81.43';
     inputElement20.style.fontWeight = 'bold';
     inputElement20.style.borderRadius = '5px'; // 设置圆角
     inputElement20.style.width = '42px';
     inputElement20.style.height = '18px'
     inputElement20.style.backgroundColor = '#fff';
     
 
     const button39 = document.createElement('img');
     button39.src = './img/up.png';
     button39.style.width = '14px';
     button39.style.height = '8px';
     button39.style.cursor = 'pointer';
     button39.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement20.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement20.value = (currentValue + increment).toFixed(2);
    });
     
 
     const button40 = document.createElement('img');
     button40.src = './img/next.png';
     button40.style.width = '14px';
     button40.style.height = '8px';
     button40.style.cursor = 'pointer';
     button40.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement20.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement20.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
 
    
     const buttondiv20 = document.createElement('div');
     buttondiv20.style.display = 'flex';  // 设置为flex布局
     buttondiv20.style.flexDirection = 'column';
     buttondiv20.style.paddingTop = '2px'
     buttondiv20.style.gap = '2px'
     buttondiv20.appendChild(button39);
     buttondiv20.appendChild(button40);
     
 
     const childdiv20 = document.createElement('div');
     childdiv20.style.display = 'flex';  // 设置为flex布局
     childdiv20.style.marginLeft = '-20px'
     childdiv20.appendChild(Serial20);
     childdiv20.appendChild(inputElement20)
     childdiv20.appendChild(buttondiv20);
 
     childDiv12.appendChild(childdiv20);
     
     
     newDialog1.appendChild(childDiv12);







     //第十三div
     const childDiv13 = document.createElement('div');
     childDiv13.style.paddingTop = '10px'
     childDiv13.style.paddingLeft = '30px'
     childDiv13.style.backgroundColor = '#fff'
     childDiv13.style.display = 'flex';  // 设置为flex布局
 
     const Serial21 = document.createElement('p');
     Serial21.textContent = '45';
     Serial21.style.fontWeight = 'bold';
     Serial21.style.marginRight = '6px';
     Serial21.style.fontSize = '14px';
     Serial21.style.marginLeft = '50px';
     
 
     const inputElement21 = document.createElement('input');
     inputElement21.type = 'text';
     inputElement21.value = '99.23';
     inputElement21.style.fontWeight = 'bold';
     inputElement21.style.borderRadius = '5px'; // 设置圆角
     inputElement21.style.width = '42px';
     inputElement21.style.height = '18px'
     inputElement21.style.backgroundColor = '#fff';
     
 
     const button41 = document.createElement('img');
     button41.src = './img/up.png';
     button41.style.width = '14px';
     button41.style.height = '8px';
     button41.style.cursor = 'pointer';
     button41.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement21.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement21.value = (currentValue + increment).toFixed(2);
    });
     
 
     const button42 = document.createElement('img');
     button42.src = './img/next.png';
     button42.style.width = '14px';
     button42.style.height = '8px';
     button42.style.cursor = 'pointer';
     button42.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement21.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement21.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
 
    
     const buttondiv21 = document.createElement('div');
     buttondiv21.style.display = 'flex';  // 设置为flex布局
     buttondiv21.style.flexDirection = 'column';
     buttondiv21.style.paddingTop = '2px'
     buttondiv21.style.gap = '1px'
     buttondiv21.appendChild(button41);
     buttondiv21.appendChild(button42);
     
 
     const childdiv21 = document.createElement('div');
     childdiv21.style.display = 'flex';  // 设置为flex布局
     childdiv21.style.marginLeft = '-40px'
     childdiv21.style.position = 'relative';
     childdiv21.appendChild(Serial21);
     childdiv21.appendChild(inputElement21)
     childdiv21.appendChild(buttondiv21);
 
     childDiv13.appendChild(childdiv21);
 
     const Serial22 = document.createElement('p');
     Serial22.textContent = '35';
     Serial22.style.fontWeight = 'bold';
     Serial22.style.marginRight = '6px';
     Serial22.style.fontSize = '14px';
     Serial22.style.marginLeft = '75px';
     
 
     const inputElement22 = document.createElement('input');
     inputElement22.type = 'text';
     inputElement22.value = '96.76';
     inputElement22.style.fontWeight = 'bold';
     inputElement22.style.borderRadius = '5px'; // 设置圆角
     inputElement22.style.width = '42px';
     inputElement22.style.height = '18px'
     inputElement22.style.backgroundColor = '#fff';
     
 
     const button43 = document.createElement('img');
     button43.src = './img/up.png';
     button43.style.width = '14px';
     button43.style.height = '8px';
     button43.style.cursor = 'pointer';
     button43.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement22.value);
      // 递增值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement22.value = (currentValue + increment).toFixed(2);
    });
     
 
     const button44 = document.createElement('img');
     button44.src = './img/next.png';
     button44.style.width = '14px';
     button44.style.height = '8px';
     button44.style.cursor = 'pointer';
     button44.addEventListener('click', () => {
      // 获取当前输入框的值，将其转换为浮点数
      let currentValue = parseFloat(inputElement22.value);
      // 递减值
      const increment = 1.00;
      // 更新输入框的值，并保留一位小数
      inputElement22.value = Math.max((currentValue - increment).toFixed(2), 0);
    });
 
    
     const buttondiv22 = document.createElement('div');
     buttondiv22.style.display = 'flex';  // 设置为flex布局
     buttondiv22.style.flexDirection = 'column';
     buttondiv22.style.paddingTop = '2px'
     buttondiv22.style.gap = '1px'
     buttondiv22.appendChild(button43);
     buttondiv22.appendChild(button44);
     
 
     const childdiv22 = document.createElement('div');
     childdiv22.style.display = 'flex';  // 设置为flex布局
     childdiv22.style.marginLeft = '-20px'
     childdiv22.appendChild(Serial22);
     childdiv22.appendChild(inputElement22)
     childdiv22.appendChild(buttondiv22);
 
     childDiv13.appendChild(childdiv22);
     
     
     newDialog1.appendChild(childDiv13);





      //第十四div
      const childDiv14 = document.createElement('div');
      childDiv14.style.paddingTop = '10px'
      childDiv14.style.paddingLeft = '30px'
      childDiv14.style.paddingBottom = '10px'
      childDiv14.style.backgroundColor = '#fff'
      childDiv14.style.display = 'flex';  // 设置为flex布局
  
      const Serial23 = document.createElement('p');
      Serial23.textContent = '46';
      Serial23.style.fontWeight = 'bold';
      Serial23.style.marginRight = '6px';
      Serial23.style.fontSize = '14px';
      Serial23.style.marginLeft = '50px';
      
  
      const inputElement23 = document.createElement('input');
      inputElement23.type = 'text';
      inputElement23.value = '92.65';
      inputElement23.style.fontWeight = 'bold';
      inputElement23.style.borderRadius = '5px'; // 设置圆角
      inputElement23.style.width = '42px';
      inputElement23.style.height = '18px'
      inputElement23.style.backgroundColor = '#fff';
      
  
      const button45 = document.createElement('img');
      button45.src = './img/up.png';
      button45.style.width = '14px';
      button45.style.height = '8px';
      button45.style.cursor = 'pointer';
      button45.addEventListener('click', () => {
        // 获取当前输入框的值，将其转换为浮点数
        let currentValue = parseFloat(inputElement23.value);
        // 递增值
        const increment = 1.00;
        // 更新输入框的值，并保留一位小数
        inputElement23.value = (currentValue + increment).toFixed(2);
      });
      
  
      const button46 = document.createElement('img');
      button46.src = './img/next.png';
      button46.style.width = '14px';
      button46.style.height = '8px';
      button46.style.cursor = 'pointer';
      button46.addEventListener('click', () => {
        // 获取当前输入框的值，将其转换为浮点数
        let currentValue = parseFloat(inputElement23.value);
        // 递减值
        const increment = 1.00;
        // 更新输入框的值，并保留一位小数
        inputElement23.value = Math.max((currentValue - increment).toFixed(2), 0);
      });
  
     
      const buttondiv23 = document.createElement('div');
      buttondiv23.style.display = 'flex';  // 设置为flex布局
      buttondiv23.style.flexDirection = 'column';
      buttondiv23.style.paddingTop = '2px'
      buttondiv23.style.gap = '1px'
      buttondiv23.appendChild(button45);
      buttondiv23.appendChild(button46);
      
  
      const childdiv23 = document.createElement('div');
      childdiv23.style.display = 'flex';  // 设置为flex布局
      childdiv23.style.marginLeft = '-40px'
      childdiv23.style.position = 'relative';
      childdiv23.appendChild(Serial23);
      childdiv23.appendChild(inputElement23)
      childdiv23.appendChild(buttondiv23);
  
      childDiv14.appendChild(childdiv23);
  
      const Serial24 = document.createElement('p');
      Serial24.textContent = '36';
      Serial24.style.fontWeight = 'bold';
      Serial24.style.marginRight = '6px';
      Serial24.style.fontSize = '14px';
      Serial24.style.marginLeft = '75px';
      
  
      const inputElement24 = document.createElement('input');
      inputElement24.type = 'text';
      inputElement24.value = '56.38';
      inputElement24.style.fontWeight = 'bold';
      inputElement24.style.borderRadius = '5px'; // 设置圆角
      inputElement24.style.width = '42px';
      inputElement24.style.height = '18px'
      inputElement24.style.backgroundColor = '#fff';
      
  
      const button47 = document.createElement('img');
      button47.src = './img/up.png';
      button47.style.width = '14px';
      button47.style.height = '8px';
      button47.style.cursor = 'pointer';
      button47.addEventListener('click', () => {
        // 获取当前输入框的值，将其转换为浮点数
        let currentValue = parseFloat(inputElement24.value);
        // 递增值
        const increment = 1.00;
        // 更新输入框的值，并保留一位小数
        inputElement24.value = (currentValue + increment).toFixed(2);
      });
      
  
      const button48 = document.createElement('img');
      button48.src = './img/next.png';
      button48.style.width = '14px';
      button48.style.height = '8px';
      button48.style.cursor = 'pointer';
      button48.addEventListener('click', () => {
        // 获取当前输入框的值，将其转换为浮点数
        let currentValue = parseFloat(inputElement24.value);
        // 递减值
        const increment = 1.00;
        // 更新输入框的值，并保留一位小数
        inputElement24.value = Math.max((currentValue - increment).toFixed(2), 0);
      });
  
     
      const buttondiv24 = document.createElement('div');
      buttondiv24.style.display = 'flex';  // 设置为flex布局
      buttondiv24.style.flexDirection = 'column';
      buttondiv24.style.paddingTop = '2px'
      buttondiv24.style.gap = '1px'
      buttondiv24.appendChild(button47);
      buttondiv24.appendChild(button48);
      
  
      const childdiv24 = document.createElement('div');
      childdiv24.style.display = 'flex';  // 设置为flex布局
      childdiv24.style.marginLeft = '-20px'
      childdiv24.appendChild(Serial24);
      childdiv24.appendChild(inputElement24)
      childdiv24.appendChild(buttondiv24);
  
      childDiv14.appendChild(childdiv24);
      
      
      newDialog1.appendChild(childDiv14);


      const hrElement3 = document.createElement('hr');
      newDialog1.appendChild(hrElement3);

      const childDiv15 = document.createElement('div');
      childDiv15.style.backgroundColor = '#fff'
      childDiv15.style.paddingTop = '10px';
      childDiv15.style.display = 'flex';  // 设置为flex布局

      const checkboxElement = document.createElement('input');
      checkboxElement.type = 'checkbox';

      checkboxElement.style.width = '15px';
      checkboxElement.style.height = '15px';
      checkboxElement.style.margin = '4px';
      childDiv15.appendChild(checkboxElement);

      const Serial25 = document.createElement('p');
      Serial25.textContent = '在牙齿上显示';
      Serial25.style.fontSize = '16px';
      Serial25.style.marginLeft = '5px';
      childDiv15.appendChild(Serial25)

      newDialog1.appendChild(childDiv15);

      const childDiv16 = document.createElement('div');
      childDiv16.style.backgroundColor = '#fff'
      childDiv16.style.paddingTop = '20px';
      childDiv16.style.paddingBottom = '30px'
      childDiv16.style.paddingLeft = '70px'
      childDiv16.style.borderBottomLeftRadius = '10px';
      childDiv16.style.borderBottomRightRadius = '10px';

      const button = document.createElement('button');
      button.textContent = '重置牙齿宽度';
      button.style.backgroundColor = 'red';
      button.style.border = 'none';
      button.style.color = 'white';
      button.style.width = '150px';
      button.style.height = '30px';
      button.style.borderRadius = '10px';;
      childDiv16.appendChild(button);

      newDialog1.appendChild(childDiv16);

      let isDragging = false;
      let offsetX, offsetY;

      // 鼠标按下事件
      newDialog1.addEventListener('mousedown', (e) => {
        isDragging = true;
        offsetX = e.clientX - newDialog1.getBoundingClientRect().left;
        offsetY = e.clientY - newDialog1.getBoundingClientRect().top;
      });

      // 鼠标移动事件
      document.addEventListener('mousemove', (e) => {
        if (isDragging) {
          newDialog1.style.left = `${e.clientX - offsetX}px`;
          newDialog1.style.top = `${e.clientY - offsetY}px`;
        }
      });

      // 鼠标释放事件
      document.addEventListener('mouseup', () => {
        isDragging = false;
      });


    // 添加到场景
    document.body.appendChild(newDialog1);
    
  };

  // 更新文本内容的函数
  const updateAdditionalText = (element, value) => {
    const currentText = parseFloat(element.textContent);
    const newText = currentText + value;
  
    element.textContent = newText.toFixed(1);
  };

  // 隐藏对话框1
  const hideDialog1 = () => {
    const dialog1 = document.getElementById('newDialog1');
    if (dialog1) {
     
      dialog1.remove();
    }
  }


  return (
    <div>
      <Button onClick={() => showNewDialog1(150,1220)}>
        <img 
          src='./img/Dialog box.png' 
          alt='button-background' 
          style={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width:'100%',height:'100%'}}/>
      </Button>
    </div>
  );
};

export default DialogComponent;
