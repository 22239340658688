import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Select } from "antd";
import Sider from "antd/es/layout/Sider";
import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import { TransformControls } from "../../../TransformControls/TransformControls";
import * as TWEEN from 'three/examples/jsm/libs/tween.module.js';

import { Link } from "react-router-dom";

import "./Three.css";

import DialogComponent from "./Dialog";
import Player from "../../../Progress bar/Progress_bar";
import TransparentController from "./TransparentController";

const Modelrender = ({}) => {
  const { Option } = Select;
  const canvasRef = useRef(null);
  const switchButtonRef = useRef(null); //牙颌正视角
  const switchButtonRef1 = useRef(null); //下颌视角
  const [tem, setTem] = useState(""); //病例

  const buttonsRef = useRef([]);
  const sphereCountRef = useRef(1); //定义上颌小球起点
  const sphereCountRef1 = useRef(17); //定义下颌小球起点
  const [youModeldata, setYouModeldata] = useState({ upper: {}, lower: {} }); //小球json数据

  const curves = useRef(null); //曲线显示隐藏函数
  const grids = useRef(null); //网格显示隐藏函数

  const [selectedOption, setSelectedOption] = useState(""); //下拉框的选项储存
  const [models, setModels] = useState([]); // 用于存储加载的模型对象

  const planes = useRef([]); //曲线平面切换显示隐藏

  const figure = useRef([]); //数字序号切换显示隐藏

  const [isPlayerVisible, setIsPlayerVisible] = useState(false); //播放器进度条

  const modelRef = useRef([])//透明度状态储存

  const [modelPosition,setModelPosition] = useState([]);

  const tweenRef = useRef([]); // 存储每个模型的 tweenRef
  const [isPlaying, setIsPlaying] = useState(false);
  const meshRef = useRef([]); // 存储每个模型的引用
  

  useEffect(() => {
    // 初始化场景、渲染器和相机
    const canvas = canvasRef.current;
    const width = window.innerWidth * 0.65; // 计算宽度为窗口宽度的80%
    const height = window.innerHeight * 0.8; // 计算高度为窗口高度的80%
    const renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true,
    });
    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio || 1);
    const scene = new THREE.Scene();
    scene.background = new THREE.Color("width");
    const camera = new THREE.PerspectiveCamera(
      80,
      canvas.clientWidth / canvas.clientHeight,
      1,
      1100
    );
    camera.position.z = 85;
    camera.lookAt(0, 0, 0);

    const grid = new THREE.GridHelper(100, 2000);
    grid.rotation.x = Math.PI / 2;
    const gridDistance = 1;
    camera.add(grid);
    grid.visible = false;
    grid.position.z = -gridDistance;
    scene.add(camera);

    grids.current = grid;

    /*----------------------------------------------------=>初始化分割线<=---------------------------------------------------------------------*/
    //将模型打包到group组方便渲染
    const group = new THREE.Group(); //下颌组
    const group1 = new THREE.Group(); //上颌组
    const groupstl = new THREE.Group(); //牙龈组

    const planeClick1 = new THREE.Group(); //IPR点击组
    const planeClick2 = new THREE.Group(); //IPR点击组
    const planeClick3 = new THREE.Group(); //IPR点击组
    const planeClick4 = new THREE.Group(); //IPR点击组
    const planeClick5 = new THREE.Group(); //IPR点击组
    const planeClick6 = new THREE.Group(); //IPR点击组
    const planeClick7 = new THREE.Group(); //IPR点击组
    const planeClick8 = new THREE.Group(); //IPR点击组
    const planeClick9 = new THREE.Group(); //IPR点击组
    const planeClick10 = new THREE.Group(); //IPR点击组
    const planeClick11 = new THREE.Group(); //IPR点击组
    const planeClick12 = new THREE.Group(); //IPR点击组
    const planeClick13 = new THREE.Group(); //IPR点击组

    const modelstl = "./lower/m_CutGumPd0_14.stl"; //牙龈数据
    //牙齿数据
    const Modeltooth = [
      "./lower/m_CutGumPd0_0.stl",
      "./lower/m_CutGumPd0_13.stl",
    ]; //第一组

    const Modeltooth1 = [
      "./lower/m_CutGumPd0_1.stl",
      "./lower/m_CutGumPd0_2.stl",
    ]; //第二组

    const Modeltooth2 = [
      "./lower/m_CutGumPd0_3.stl",
      "./lower/m_CutGumPd0_4.stl",
    ]; //第三组

    const Modeltooth3 = [
      "./lower/m_CutGumPd0_5.stl",
      "./lower/m_CutGumPd0_6.stl",
    ]; //第四组

    const Modeltooth4 = [
      "./lower/m_CutGumPd0_7.stl",
      "./lower/m_CutGumPd0_8.stl",
    ]; //第五组

    const Modeltooth5 = [
      "./lower/m_CutGumPd0_9.stl",
      "./lower/m_CutGumPd0_10.stl",
    ]; //第六组

    const Modeltooth6 = [
      "./lower/m_CutGumPd0_11.stl",
      "./lower/m_CutGumPd0_12.stl",
    ]; //第七组

    const loader = new STLLoader();

    //第一组模型
    let totalCenter = new THREE.Vector3();
    let centerPoints = [];

    //动画起始位置
    const startPositions = [
      new THREE.Vector3(21.112296104431152,-1.6030312776565552,13.00271224975586),
      new THREE.Vector3(24.880552291870117,-1.177345871925354,4.730936408042908),
    ]
    const startPositions1 = [
      new THREE.Vector3(18.465723991394043,-2.197504758834839,22.48686122894287),
      new THREE.Vector3(16.18462085723877,-2.00172221660614,29.83222007751465),
    ]
    const startPositions2 = [
      new THREE.Vector3(11.787595748901367,-1.1927775144577026,35.34723472595215),
      new THREE.Vector3(8.10631513595581,-0.7687969207763672,39.11325454711914),
    ]
    const startPositions3 = [
      new THREE.Vector3(3.369407534599304,-0.4379234313964844,40.857017517089844),
      new THREE.Vector3(-1.9808962941169739,-0.03953361511230469,40.9727840423584),
    ]
    const startPositions4 = [
      new THREE.Vector3(-7.227514028549194,0.012157917022705078,39.918190002441406),
      new THREE.Vector3( -11.863919734954834,-0.22327017784118652,37.0277214050293),
    ]
    const startPositions5 = [
      new THREE.Vector3(-16.667680263519287,-1.8516933917999268,32.26440715789795),
      new THREE.Vector3(-19.55568838119507,-1.720902919769287,25.316303253173828),
    ]
    const startPositions6 = [
      new THREE.Vector3(-22.53939151763916,-1.79774010181427,16.181971549987793),
      new THREE.Vector3(-26.888155937194824,-1.7722281217575073,7.332047939300537),
    ]

    //动画结束位置 
    const endPosition = [
      new THREE.Vector3(25.894474758903463,-1.6030312776565552,13.00271224975586),
      new THREE.Vector3(30.926677532282937,-1.177345871925354,4.730936408042908), 
    ];
    const endPosition1 = [
      new THREE.Vector3(22.60012703345572,-2.197504758834839,22.48686122894287),
      new THREE.Vector3(19.99863744729242,-2.00172221660614,29.83222007751465),
    ]
    const endPosition2 = [
      new THREE.Vector3(15.42525622379394,-1.1927775144577026,35.34723472595215),
      new THREE.Vector3(10.163291766920082,-0.7687969207763672,41.390570127965816),
    ]
    const endPosition3 = [
      new THREE.Vector3(3.369407534599304,-0.4379234313964844,43.49735572071783),
      new THREE.Vector3(-1.9808962941169739,-0.03953361511230469,44.29948313296382),
    ]
    const endPosition4 = [
      new THREE.Vector3(-9.400666444412977,0.012157917022705078,43.30514938776099),
      new THREE.Vector3(-15.259044093293195,-0.22327017784118652,39.64304326129406),
    ]
    const endPosition5 = [
      new THREE.Vector3(-20.530855043152318,-1.8516933917999268,32.26440715789795),
      new THREE.Vector3(-24.90705986832384,-1.720902919769287,25.316303253173828),
    ]
    const endPosition6 = [
      new THREE.Vector3(-28.531772510731557,-1.79774010181427,16.181971549987793),
      new THREE.Vector3(-31.945980297024466,-1.7722281217575073,7.332047939300537),
    ]


    Modeltooth.forEach((filePath,index) => {
      loader.load(filePath, (geometry) => {
        const positions = geometry.getAttribute("position").array;
        // 找到模型顶点坐标的最小值和最大值
        let minX = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          minX = Math.min(minX, x);
          maxX = Math.max(maxX, x);
          minY = Math.min(minY, y);
          maxY = Math.max(maxY, y);
        }
        // 计算顶点坐标范围
        const rangeX = maxX - minX;
        const rangeY = maxY - minY;
        // 计算 UV 坐标并归一化到 0 到 1 之间
        const uvs = [];
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          const u = (x - minX) / rangeX;
          const v = (y - minY) / rangeY;

          uvs.push(u, v);
        }

        geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));

        const material = new THREE.ShaderMaterial({
          uniforms: {
            texturetooth: { value: texture },
            opacity: { value: 1.0 },
          },
          vertexShader: vertexShader,
          fragmentShader: fragmentShader,
          transparent: true,
        });
        const mesh = new THREE.Mesh(geometry, material);

        const bbox = new THREE.Box3().setFromObject(mesh);
        const center = bbox.getCenter(new THREE.Vector3());

        mesh.geometry.translate(-center.x, -center.y, -center.z);
        mesh.position.add(center);
        mesh.position.copy(startPositions[index]);
        meshRef.current[index] = mesh;

        const tween = new TWEEN.Tween(startPositions[index])
      .to(endPosition[index], 3000)
      .onUpdate(() => {
        mesh.position.copy(startPositions[index]);
      })
      .onComplete(() => {
        setIsPlaying(false);
      });
     tweenRef.current.push(tween); // 将每个 Tween 对象添加到数组中
          

        group.add(mesh);
        modelRef.current.push(mesh); // 将模型引用保存在 ref 中
        setModelPosition(prev => [...prev,mesh.position]);


        const planeGeometry = new THREE.PlaneGeometry(1, 1);
        const textureUrl = "./img/Sn.png";
        textureLoader.load(textureUrl, (texture) => {
          const material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            side: THREE.DoubleSide,
          });
          const plane1 = new THREE.Mesh(planeGeometry, material);
          plane1.position.copy(center);
          plane1.rotation.y = Math.PI / 2;
          plane1.position.x += 5;
          plane1.position.z += 2.5;
          plane1.visible = false;
          group.add(plane1);

          figure.current.push({ plane: plane1 });
        });

        totalCenter.add(center);
        centerPoints.push(center);

        const loadModel = (path, identifier) => {
          loader.load(path, (geometry) => {
            const positions = geometry.getAttribute("position").array;
            // 找到模型顶点坐标的最小值和最大值
            let minX = Number.POSITIVE_INFINITY;
            let maxX = Number.NEGATIVE_INFINITY;
            let minY = Number.POSITIVE_INFINITY;
            let maxY = Number.NEGATIVE_INFINITY;
            for (let i = 0; i < positions.length; i += 3) {
              const x = positions[i];
              const y = positions[i + 1];

              minX = Math.min(minX, x);
              maxX = Math.max(maxX, x);
              minY = Math.min(minY, y);
              maxY = Math.max(maxY, y);
            }
            // 计算顶点坐标范围
            const rangeX = maxX - minX;
            const rangeY = maxY - minY;
            // 计算 UV 坐标并归一化到 0 到 1 之间
            const uvs = [];
            for (let i = 0; i < positions.length; i += 3) {
              const x = positions[i];
              const y = positions[i + 1];

              const u = (x - minX) / rangeX;
              const v = (y - minY) / rangeY;

              uvs.push(u, v);
            }

            geometry.setAttribute(
              "uv",
              new THREE.Float32BufferAttribute(uvs, 2)
            );
            const material = new THREE.ShaderMaterial({
              uniforms: {
                texturetooth: { value: texture },
              },
              vertexShader: vertexShader1,
              fragmentShader: fragmentShader1,
            });
            const currentModel = new THREE.Mesh(geometry, material);
            currentModel.position.copy(center);
            currentModel.visible = false;
            currentModel.rotation.y = Math.PI / 2;
            currentModel.position.x += 5;
            currentModel.position.z += 2;
            group.add(currentModel);
            setModels((prevModels) => [
              ...prevModels,
              { id: identifier, model: currentModel },
            ]);
          });
        };

        // 加载模型
        loadModel("./annex/a1.stl", "model1");
        loadModel("./annex/a3.stl", "model2");
        loadModel("./annex/b1.stl", "model3");
        loadModel("./annex/b4.stl", "model4");
      });
    });


    let endPoint1;
    let sprite1;
    let sprites1;
    Promise.all(
      Modeltooth.map(
        (filePath) => new Promise((resolve) => loader.load(filePath, resolve))
      )
    ).then(() => {
      const textureLoader = new THREE.TextureLoader();

      // 计算两个模型中心点之间的中心点
      const distanceCenter = new THREE.Vector3().lerpVectors(
        centerPoints[0],
        centerPoints[1],
        0.5
      );

      const extensionDistance = 2;
      const yOffset = -5;

      endPoint1 = distanceCenter
        .clone()
        .add(new THREE.Vector3(10, yOffset, extensionDistance));

      const curve = new THREE.CatmullRomCurve3([
        distanceCenter.clone(),
        endPoint1.clone(),
      ]);

      // 创建曲线的几何体
      const curveGeometry = new THREE.BufferGeometry().setFromPoints(
        curve.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterial = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObject1 = new THREE.Line(curveGeometry, curveMaterial);
      curveObject1.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObject1);

      const texture = textureLoader.load("./img/jia.png");
      const planeMaterial = new THREE.SpriteMaterial({ map: texture });
      const plane1 = new THREE.Sprite(planeMaterial);
      plane1.scale.set(2, 2, 1);
      plane1.position.copy(endPoint1);
      plane1.visible = false;
      planeClick1.add(plane1);

      // 创建起始点
      const startPoints = new THREE.Vector3(20, -2, 20);

      // 设置延伸方向和距离
      const extensionDirections = new THREE.Vector3(5, -3, 0).normalize(); // 向外延伸的方向
      const extensionDistances = 12; // 延伸的距离
      // 计算终点
      const endPointEnd1 = startPoints
        .clone()
        .add(extensionDirections.multiplyScalar(extensionDistances));
      // 创建 Catmull-Rom 曲线
      const curves = new THREE.CatmullRomCurve3([startPoints, endPointEnd1]);
      // 创建曲线的几何体
      const curveGeometrys = new THREE.BufferGeometry().setFromPoints(
        curves.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterials = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObjectlines1 = new THREE.Line(curveGeometrys, curveMaterials);
      curveObjectlines1.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObjectlines1);

      const textures = textureLoader.load("./img/jia.png");
      const planeMaterials = new THREE.SpriteMaterial({ map: textures });
      const planelines1 = new THREE.Sprite(planeMaterials);
      planelines1.scale.set(2, 2, 1);
      planelines1.position.copy(endPointEnd1);
      planelines1.visible = false;
      planeClick2.add(planelines1);

      planes.current.push({
        curveObject: curveObject1,
        plane: plane1,
        planeline1: planelines1,
        curveObjectline1: curveObjectlines1,
      });

      const spriteMaterial1 = new THREE.SpriteMaterial();
      sprite1 = new THREE.Sprite(spriteMaterial1);
      sprite1.position.copy(endPointEnd1);
      sprite1.visible = false;
      sprite1.position.y -= 3;
      sprite1.scale.set(10, 10, 1);
      scene.add(sprite1);

      const spriteMaterial2 = new THREE.SpriteMaterial();
      sprites1 = new THREE.Sprite(spriteMaterial2);
      sprites1.position.copy(endPoint1);
      sprites1.visible = false;
      sprites1.position.y -= 3;
      sprites1.scale.set(10, 10, 1);
      scene.add(sprites1);

      // 创建包含图标的 Canvas
      const canvas2 = document.createElement("canvas");
      const ctx = canvas2.getContext("2d");
      canvas2.width = 188;
      canvas2.height = 188;

      // 绘制灰色背景
      ctx.fillStyle = "rgba(220, 220, 220, 1)";
      ctx.fillRect(0, -10, 180, 80);

      // 加载图标到 Canvas
      const icon = new Image();
      icon.src = "./img/icons8-multiply-24.png";
      icon.onload = () => {
        // 调整图标大小并绘制到 Canvas
        ctx.drawImage(icon, 10, 10, 40, 40);

        // 设置文本样式
        ctx.font = "30px Arial";
        ctx.fillStyle = "Black";

        // 绘制文本
        ctx.fillText("0.1", 70, 40);

        const anotherImage = new Image();

        anotherImage.src = "./img/next.png";
        anotherImage.onload = () => {
          ctx.drawImage(anotherImage, 140, 36, 35, 20);

          const thirdImage = new Image();
          thirdImage.src = "./img/up.png";
          thirdImage.onload = () => {
            ctx.drawImage(thirdImage, 140, 8, 35, 20);

            // 将 Canvas 转换为 Three.js 纹理
            const texture = new THREE.CanvasTexture(canvas2);

            // 应用纹理到精灵材质
            spriteMaterial2.map = texture;
            spriteMaterial2.needsUpdate = true; // 标记纹理需要更新
            spriteMaterial1.map = texture;
            spriteMaterial1.needsUpdate = true; // 标记纹理需要更新
          };
        };
      };
    });

    //第二组模型
    let totalCenter1 = new THREE.Vector3();
    let centerPoints1 = [];

    Modeltooth1.forEach((filePath1,index) => {
      loader.load(filePath1, (geometry) => {
        const positions = geometry.getAttribute("position").array;
        // 找到模型顶点坐标的最小值和最大值
        let minX = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          minX = Math.min(minX, x);
          maxX = Math.max(maxX, x);
          minY = Math.min(minY, y);
          maxY = Math.max(maxY, y);
        }
        // 计算顶点坐标范围
        const rangeX = maxX - minX;
        const rangeY = maxY - minY;
        // 计算 UV 坐标并归一化到 0 到 1 之间
        const uvs = [];
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          const u = (x - minX) / rangeX;
          const v = (y - minY) / rangeY;

          uvs.push(u, v);
        }

        geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));

        const material = new THREE.ShaderMaterial({
          uniforms: {
            texturetooth: { value: texture },
          },
          vertexShader: vertexShader,
          fragmentShader: fragmentShader,
          transparent:true,
        });
        const mesh = new THREE.Mesh(geometry, material);

        const bbox = new THREE.Box3().setFromObject(mesh);
        const center = bbox.getCenter(new THREE.Vector3());

        mesh.geometry.translate(-center.x, -center.y, -center.z);
        mesh.position.add(center);
        mesh.position.copy(startPositions1[index]);
        meshRef.current[index] = mesh;

        const tween = new TWEEN.Tween(startPositions1[index])
      .to(endPosition1[index], 3000)
      .onUpdate(() => {
        mesh.position.copy(startPositions1[index]);
      })
      .onComplete(() => {
        setIsPlaying(false);
      });
     tweenRef.current.push(tween); // 将每个 Tween 对象添加到数组中

        group.add(mesh);
        setModelPosition(prev => [...prev,mesh.position]);

        const planeGeometry = new THREE.PlaneGeometry(1, 1);
        const textureUrl = "./img/Sn.png";
        textureLoader.load(textureUrl, (texture) => {
          const material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            side: THREE.DoubleSide,
          });
          const plane2 = new THREE.Mesh(planeGeometry, material);
          plane2.position.copy(center);
          plane2.rotation.y = Math.PI / 2;
          plane2.position.x += 4;
          plane2.position.z += 2;
          plane2.visible = false;
          group.add(plane2);

          figure.current.push({ plane: plane2 });
        });

        totalCenter1.add(center);
        centerPoints1.push(center);

        const loadModel = (path, identifier) => {
          loader.load(path, (geometry) => {
            const material = new THREE.MeshPhongMaterial({
              color: "LightCoral",
            });
            const currentModel = new THREE.Mesh(geometry, material);
            currentModel.position.copy(center);
            currentModel.visible = false;
            currentModel.rotation.y = Math.PI / 2;
            currentModel.position.x += 4;
            currentModel.position.z += 1.5;
            group.add(currentModel);
            setModels((prevModels) => [
              ...prevModels,
              { id: identifier, model: currentModel },
            ]);
          });
        };

        // 加载模型
        loadModel("./annex/a1.stl", "model1");
        loadModel("./annex/a3.stl", "model2");
        loadModel("./annex/b1.stl", "model3");
        loadModel("./annex/b4.stl", "model4");
      });
    });

    let endPoint2;
    let sprite2;
    let sprites2;
    Promise.all(
      Modeltooth1.map(
        (filePath1) => new Promise((resolve) => loader.load(filePath1, resolve))
      )
    ).then(() => {
      // 计算两个模型中心点之间的中心点
      const distanceCenter = new THREE.Vector3().lerpVectors(
        centerPoints1[0],
        centerPoints1[1],
        0.5
      );

      const extensionDistance = 3;
      const yOffset = -5;

      endPoint2 = distanceCenter
        .clone()
        .add(new THREE.Vector3(8, yOffset, extensionDistance));

      const curve = new THREE.CatmullRomCurve3([
        distanceCenter.clone(),
        endPoint2.clone(),
      ]);

      // 创建曲线的几何体
      const curveGeometry = new THREE.BufferGeometry().setFromPoints(
        curve.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterial = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObject2 = new THREE.Line(curveGeometry, curveMaterial);
      curveObject2.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObject2);

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("./img/jia.png");
      const planeMaterial = new THREE.SpriteMaterial({ map: texture });
      const plane2 = new THREE.Sprite(planeMaterial);
      plane2.scale.set(2, 2, 1);
      plane2.position.copy(endPoint2);
      plane2.visible = false;
      planeClick3.add(plane2);

      // 创建起始点
      const startPoints = new THREE.Vector3(15, -2, 34);
      // 设置延伸方向和距离
      const extensionDirections = new THREE.Vector3(5, -3, 3).normalize(); // 向外延伸的方向
      const extensionDistances = 10; // 延伸的距离
      // 计算终点
      const endPointEnd2 = startPoints
        .clone()
        .add(extensionDirections.multiplyScalar(extensionDistances));
      // 创建 Catmull-Rom 曲线
      const curves = new THREE.CatmullRomCurve3([startPoints, endPointEnd2]);
      // 创建曲线的几何体
      const curveGeometrys = new THREE.BufferGeometry().setFromPoints(
        curves.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterials = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObjectlines2 = new THREE.Line(curveGeometrys, curveMaterials);
      curveObjectlines2.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObjectlines2);

      const textures = textureLoader.load("./img/jia.png");
      const planeMaterials = new THREE.SpriteMaterial({ map: textures });
      const planelines2 = new THREE.Sprite(planeMaterials);
      planelines2.scale.set(2, 2, 1);
      planelines2.position.copy(endPointEnd2);
      planelines2.visible = false;
      planeClick4.add(planelines2);

      planes.current.push({
        curveObject: curveObject2,
        plane: plane2,
        planeline1: planelines2,
        curveObjectline1: curveObjectlines2,
      });

      const spriteMaterial1 = new THREE.SpriteMaterial();
      sprite2 = new THREE.Sprite(spriteMaterial1);
      sprite2.position.copy(endPointEnd2);
      sprite2.visible = false;
      sprite2.position.y -= 3;
      sprite2.scale.set(10, 10, 1);
      scene.add(sprite2);

      const spriteMaterial2 = new THREE.SpriteMaterial();
      sprites2 = new THREE.Sprite(spriteMaterial2);
      sprites2.position.copy(endPoint2);
      sprites2.visible = false;
      sprites2.position.y -= 3;
      sprites2.scale.set(10, 10, 1);
      scene.add(sprites2);

      // 创建包含图标的 Canvas
      const canvas2 = document.createElement("canvas");
      const ctx = canvas2.getContext("2d");
      canvas2.width = 188;
      canvas2.height = 188;

      // 绘制灰色背景
      ctx.fillStyle = "rgba(220, 220, 220, 1)";
      ctx.fillRect(0, -10, 180, 80);

      // 加载图标到 Canvas
      const icon = new Image();
      icon.src = "./img/icons8-multiply-24.png";
      icon.onload = () => {
        // 调整图标大小并绘制到 Canvas
        ctx.drawImage(icon, 10, 10, 40, 40);

        // 设置文本样式
        ctx.font = "30px Arial";
        ctx.fillStyle = "Black";

        // 绘制文本
        ctx.fillText("0.1", 70, 40);

        const anotherImage = new Image();

        anotherImage.src = "./img/next.png";
        anotherImage.onload = () => {
          ctx.drawImage(anotherImage, 140, 36, 35, 20);

          const thirdImage = new Image();
          thirdImage.src = "./img/up.png";
          thirdImage.onload = () => {
            ctx.drawImage(thirdImage, 140, 8, 35, 20);

            // 将 Canvas 转换为 Three.js 纹理
            const texture = new THREE.CanvasTexture(canvas2);

            // 应用纹理到精灵材质
            spriteMaterial2.map = texture;
            spriteMaterial2.needsUpdate = true; // 标记纹理需要更新
            spriteMaterial1.map = texture;
            spriteMaterial1.needsUpdate = true; // 标记纹理需要更新
          };
        };
      };
    });

    //第三组模型
    let totalCenter2 = new THREE.Vector3();
    let centerPoints2 = [];

    Modeltooth2.forEach((filePath2,index) => {
      loader.load(filePath2, (geometry) => {
        const positions = geometry.getAttribute("position").array;
        // 找到模型顶点坐标的最小值和最大值
        let minX = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          minX = Math.min(minX, x);
          maxX = Math.max(maxX, x);
          minY = Math.min(minY, y);
          maxY = Math.max(maxY, y);
        }
        // 计算顶点坐标范围
        const rangeX = maxX - minX;
        const rangeY = maxY - minY;
        // 计算 UV 坐标并归一化到 0 到 1 之间
        const uvs = [];
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          const u = (x - minX) / rangeX;
          const v = (y - minY) / rangeY;

          uvs.push(u, v);
        }

        geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));

        const material = new THREE.ShaderMaterial({
          uniforms: {
            texturetooth: { value: texture },
          },
          vertexShader: vertexShader,
          fragmentShader: fragmentShader,
          transparent:true,
        });
        const mesh = new THREE.Mesh(geometry, material);
        const bbox = new THREE.Box3().setFromObject(mesh);
        const center = bbox.getCenter(new THREE.Vector3());

        mesh.geometry.translate(-center.x, -center.y, -center.z);
        mesh.position.add(center);
        mesh.position.copy(startPositions2[index]);
        meshRef.current[index] = mesh;

        const tween = new TWEEN.Tween(startPositions2[index])
      .to(endPosition2[index], 3000)
      .onUpdate(() => {
        mesh.position.copy(startPositions2[index]);
      })
      .onComplete(() => {
        setIsPlaying(false);
      });
     tweenRef.current.push(tween); // 将每个 Tween 对象添加到数组中
        group.add(mesh);
        setModelPosition(prev => [...prev,mesh.position]);

        const planeGeometry = new THREE.PlaneGeometry(1, 1);
        const textureUrl = "./img/Sn.png";
        textureLoader.load(textureUrl, (texture) => {
          const material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            side: THREE.DoubleSide,
          });
          const plane3 = new THREE.Mesh(planeGeometry, material);
          plane3.position.copy(center);
          plane3.rotation.y = Math.PI / 3;
          plane3.position.x += 2;
          plane3.position.z += 3.2;

          plane3.visible = false;
          group.add(plane3);

          figure.current.push({ plane: plane3 });
        });

        totalCenter2.add(center);
        centerPoints2.push(center);

        const loadModel = (path, identifier) => {
          loader.load(path, (geometry) => {
            const material = new THREE.MeshPhongMaterial({
              color: "LightCoral",
            });
            const currentModel = new THREE.Mesh(geometry, material);
            currentModel.position.copy(center);
            currentModel.visible = false;
            currentModel.rotation.y = Math.PI / 3.3;
            currentModel.position.x += 2;
            currentModel.position.z += 2.5;
            group.add(currentModel);
            setModels((prevModels) => [
              ...prevModels,
              { id: identifier, model: currentModel },
            ]);
          });
        };

        // 加载模型
        loadModel("./annex/a1.stl", "model1");
        loadModel("./annex/a3.stl", "model2");
        loadModel("./annex/b1.stl", "model3");
        loadModel("./annex/b4.stl", "model4");
      });
    });

    let endPoint3;
    let sprite3;
    let sprites3;
    Promise.all(
      Modeltooth2.map(
        (filePath2) => new Promise((resolve) => loader.load(filePath2, resolve))
      )
    ).then(() => {
      // 计算两个模型中心点之间的中心点
      const distanceCenter = new THREE.Vector3().lerpVectors(
        centerPoints2[0],
        centerPoints2[1],
        0.5
      );

      const extensionDistance = 6;
      const yOffset = -5;

      endPoint3 = distanceCenter
        .clone()
        .add(new THREE.Vector3(7, yOffset, extensionDistance));

      const curve = new THREE.CatmullRomCurve3([
        distanceCenter.clone(),
        endPoint3.clone(),
      ]);

      // 创建曲线的几何体
      const curveGeometry = new THREE.BufferGeometry().setFromPoints(
        curve.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterial = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObject3 = new THREE.Line(curveGeometry, curveMaterial);
      curveObject3.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObject3);

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("./img/jia.png");
      const planeMaterial = new THREE.SpriteMaterial({ map: texture });
      const plane3 = new THREE.Sprite(planeMaterial);
      plane3.scale.set(2, 2, 1);
      plane3.position.copy(endPoint3);
      plane3.visible = false;
      planeClick5.add(plane3);

      // 创建起始点
      const startPoints = new THREE.Vector3(5, -1, 40);
      // 设置延伸方向和距离
      const extensionDirections = new THREE.Vector3(4, -3, 7).normalize(); // 向外延伸的方向
      const extensionDistances = 10; // 延伸的距离
      // 计算终点
      const endPointEnd3 = startPoints
        .clone()
        .add(extensionDirections.multiplyScalar(extensionDistances));
      // 创建 Catmull-Rom 曲线
      const curves = new THREE.CatmullRomCurve3([startPoints, endPointEnd3]);
      // 创建曲线的几何体
      const curveGeometrys = new THREE.BufferGeometry().setFromPoints(
        curves.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterials = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObjectlines3 = new THREE.Line(curveGeometrys, curveMaterials);
      curveObjectlines3.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObjectlines3);

      const textures = textureLoader.load("./img/jia.png");
      const planeMaterials = new THREE.SpriteMaterial({ map: textures });
      const planelines3 = new THREE.Sprite(planeMaterials);
      planelines3.scale.set(2, 2, 1);
      planelines3.position.copy(endPointEnd3);
      planelines3.visible = false;
      planeClick6.add(planelines3);

      planes.current.push({
        curveObject: curveObject3,
        plane: plane3,
        planeline1: planelines3,
        curveObjectline1: curveObjectlines3,
      });

      const spriteMaterial1 = new THREE.SpriteMaterial();
      sprite3 = new THREE.Sprite(spriteMaterial1);
      sprite3.position.copy(endPointEnd3);
      sprite3.visible = false;
      sprite3.position.y -= 3;
      sprite3.scale.set(10, 10, 1);
      scene.add(sprite3);

      const spriteMaterial2 = new THREE.SpriteMaterial();
      sprites3 = new THREE.Sprite(spriteMaterial2);
      sprites3.position.copy(endPoint3);
      sprites3.visible = false;
      sprites3.position.y -= 3;
      sprites3.scale.set(10, 10, 1);
      scene.add(sprites3);

      // 创建包含图标的 Canvas
      const canvas2 = document.createElement("canvas");
      const ctx = canvas2.getContext("2d");
      canvas2.width = 188;
      canvas2.height = 188;

      // 绘制灰色背景
      ctx.fillStyle = "rgba(220, 220, 220, 1)";
      ctx.fillRect(0, -10, 180, 80);

      // 加载图标到 Canvas
      const icon = new Image();
      icon.src = "./img/icons8-multiply-24.png";
      icon.onload = () => {
        // 调整图标大小并绘制到 Canvas
        ctx.drawImage(icon, 10, 10, 40, 40);

        // 设置文本样式
        ctx.font = "30px Arial";
        ctx.fillStyle = "Black";

        // 绘制文本
        ctx.fillText("0.1", 70, 40);

        const anotherImage = new Image();

        anotherImage.src = "./img/next.png";
        anotherImage.onload = () => {
          ctx.drawImage(anotherImage, 140, 36, 35, 20);

          const thirdImage = new Image();
          thirdImage.src = "./img/up.png";
          thirdImage.onload = () => {
            ctx.drawImage(thirdImage, 140, 8, 35, 20);

            // 将 Canvas 转换为 Three.js 纹理
            const texture = new THREE.CanvasTexture(canvas2);

            // 应用纹理到精灵材质
            spriteMaterial2.map = texture;
            spriteMaterial2.needsUpdate = true; // 标记纹理需要更新
            spriteMaterial1.map = texture;
            spriteMaterial1.needsUpdate = true; // 标记纹理需要更新
          };
        };
      };
    });

    //第四组模型
    let totalCenter3 = new THREE.Vector3();
    let centerPoints3 = [];

    Modeltooth3.forEach((filePath3,index) => {
      loader.load(filePath3, (geometry) => {
        const positions = geometry.getAttribute("position").array;
        // 找到模型顶点坐标的最小值和最大值
        let minX = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          minX = Math.min(minX, x);
          maxX = Math.max(maxX, x);
          minY = Math.min(minY, y);
          maxY = Math.max(maxY, y);
        }
        // 计算顶点坐标范围
        const rangeX = maxX - minX;
        const rangeY = maxY - minY;
        // 计算 UV 坐标并归一化到 0 到 1 之间
        const uvs = [];
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          const u = (x - minX) / rangeX;
          const v = (y - minY) / rangeY;

          uvs.push(u, v);
        }

        geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));

        const material = new THREE.ShaderMaterial({
          uniforms: {
            texturetooth: { value: texture },
          },
          vertexShader: vertexShader,
          fragmentShader: fragmentShader,
          transparent:true,
        });
        const mesh = new THREE.Mesh(geometry, material);
        setModelPosition(prev => [...prev,mesh.position]);

        const bbox = new THREE.Box3().setFromObject(mesh);
        const center = bbox.getCenter(new THREE.Vector3());

        mesh.geometry.translate(-center.x, -center.y, -center.z);
        mesh.position.add(center);
        mesh.position.copy(startPositions3[index]);
        meshRef.current[index] = mesh;

        const tween = new TWEEN.Tween(startPositions3[index])
      .to(endPosition3[index], 3000)
      .onUpdate(() => {
        mesh.position.copy(startPositions3[index]);
      })
      .onComplete(() => {
        setIsPlaying(false);
      });
     tweenRef.current.push(tween); // 将每个 Tween 对象添加到数组中
        group.add(mesh);

        const planeGeometry = new THREE.PlaneGeometry(1, 1);
        const textureUrl = "./img/Sn.png";
        textureLoader.load(textureUrl, (texture) => {
          const material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            side: THREE.DoubleSide,
          });
          const plane4 = new THREE.Mesh(planeGeometry, material);
          plane4.position.copy(center);
          // plane.rotation.y = Math.PI /2
          plane4.position.x += 0;
          plane4.position.z += 4;
          plane4.visible = false;
          group.add(plane4);

          figure.current.push({ plane: plane4 });
        });

        totalCenter3.add(center);
        centerPoints3.push(center);

        const loadModel = (path, identifier) => {
          loader.load(path, (geometry) => {
            const material = new THREE.MeshPhongMaterial({
              color: "LightCoral",
            });
            const currentModel = new THREE.Mesh(geometry, material);
            currentModel.position.copy(center);
            currentModel.visible = false;
            // currentModel.rotation.y = Math.PI / 2;
            // currentModel.position.x += 5;
            currentModel.position.z += 3;
            group.add(currentModel);
            setModels((prevModels) => [
              ...prevModels,
              { id: identifier, model: currentModel },
            ]);
          });
        };

        // 加载模型
        loadModel("./annex/a1.stl", "model1");
        loadModel("./annex/a3.stl", "model2");
        loadModel("./annex/b1.stl", "model3");
        loadModel("./annex/b4.stl", "model4");
      });
    });

    let endPoint4;
    let sprite4;
    let sprites4;
    Promise.all(
      Modeltooth3.map(
        (filePath3) => new Promise((resolve) => loader.load(filePath3, resolve))
      )
    ).then(() => {
      // 计算两个模型中心点之间的中心点
      const distanceCenter = new THREE.Vector3().lerpVectors(
        centerPoints3[0],
        centerPoints3[1],
        0.5
      );

      const extensionDistance = 8;
      const yOffset = -5;

      endPoint4 = distanceCenter
        .clone()
        .add(new THREE.Vector3(0, yOffset, extensionDistance));

      const curve = new THREE.CatmullRomCurve3([
        distanceCenter.clone(),
        endPoint4.clone(),
      ]);

      // 创建曲线的几何体
      const curveGeometry = new THREE.BufferGeometry().setFromPoints(
        curve.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterial = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObject4 = new THREE.Line(curveGeometry, curveMaterial);
      curveObject4.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObject4);

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("./img/jia.png");
      const planeMaterial = new THREE.SpriteMaterial({ map: texture });
      const plane4 = new THREE.Sprite(planeMaterial);
      plane4.scale.set(2, 2, 1);
      plane4.position.copy(endPoint4);
      plane4.visible = false;
      planeClick7.add(plane4);

      // 创建起始点
      const startPoints = new THREE.Vector3(-5, -1, 40);
      // 设置延伸方向和距离
      const extensionDirections = new THREE.Vector3(-3, -10, 30).normalize(); // 向外延伸的方向
      const extensionDistances = 10; // 延伸的距离
      // 计算终点
      const endPointEnd4 = startPoints
        .clone()
        .add(extensionDirections.multiplyScalar(extensionDistances));
      // 创建 Catmull-Rom 曲线
      const curves = new THREE.CatmullRomCurve3([startPoints, endPointEnd4]);
      // 创建曲线的几何体
      const curveGeometrys = new THREE.BufferGeometry().setFromPoints(
        curves.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterials = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObjectlines4 = new THREE.Line(curveGeometrys, curveMaterials);
      curveObjectlines4.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObjectlines4);

      const textures = textureLoader.load("./img/jia.png");
      const planeMaterials = new THREE.SpriteMaterial({ map: textures });
      const planelines4 = new THREE.Sprite(planeMaterials);
      planelines4.scale.set(2, 2, 1);
      planelines4.position.copy(endPointEnd4);
      planelines4.visible = false;
      planeClick8.add(planelines4);

      planes.current.push({
        curveObject: curveObject4,
        plane: plane4,
        planeline1: planelines4,
        curveObjectline1: curveObjectlines4,
      });

      const spriteMaterial1 = new THREE.SpriteMaterial();
      sprite4 = new THREE.Sprite(spriteMaterial1);
      sprite4.position.copy(endPointEnd4);
      sprite4.visible = false;
      sprite4.position.y -= 3;
      sprite4.scale.set(10, 10, 1);
      scene.add(sprite4);

      const spriteMaterial2 = new THREE.SpriteMaterial();
      sprites4 = new THREE.Sprite(spriteMaterial2);
      sprites4.position.copy(endPoint4);
      sprites4.visible = false;
      sprites4.position.y -= 3;
      sprites4.scale.set(10, 10, 1);
      scene.add(sprites4);

      // 创建包含图标的 Canvas
      const canvas2 = document.createElement("canvas");
      const ctx = canvas2.getContext("2d");
      canvas2.width = 188;
      canvas2.height = 188;

      // 绘制灰色背景
      ctx.fillStyle = "rgba(220, 220, 220, 1)";
      ctx.fillRect(0, -10, 180, 80);

      // 加载图标到 Canvas
      const icon = new Image();
      icon.src = "./img/icons8-multiply-24.png";
      icon.onload = () => {
        // 调整图标大小并绘制到 Canvas
        ctx.drawImage(icon, 10, 10, 40, 40);

        // 设置文本样式
        ctx.font = "30px Arial";
        ctx.fillStyle = "Black";

        // 绘制文本
        ctx.fillText("0.1", 70, 40);

        const anotherImage = new Image();

        anotherImage.src = "./img/next.png";
        anotherImage.onload = () => {
          ctx.drawImage(anotherImage, 140, 36, 35, 20);

          const thirdImage = new Image();
          thirdImage.src = "./img/up.png";
          thirdImage.onload = () => {
            ctx.drawImage(thirdImage, 140, 8, 35, 20);

            // 将 Canvas 转换为 Three.js 纹理
            const texture = new THREE.CanvasTexture(canvas2);

            // 应用纹理到精灵材质
            spriteMaterial2.map = texture;
            spriteMaterial2.needsUpdate = true; // 标记纹理需要更新
            spriteMaterial1.map = texture;
            spriteMaterial1.needsUpdate = true; // 标记纹理需要更新
          };
        };
      };
    });

    //第五组模型
    let totalCenter4 = new THREE.Vector3();
    let centerPoints4 = [];

    Modeltooth4.forEach((filePath4,index) => {
      loader.load(filePath4, (geometry) => {
        const positions = geometry.getAttribute("position").array;
        // 找到模型顶点坐标的最小值和最大值
        let minX = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          minX = Math.min(minX, x);
          maxX = Math.max(maxX, x);
          minY = Math.min(minY, y);
          maxY = Math.max(maxY, y);
        }
        // 计算顶点坐标范围
        const rangeX = maxX - minX;
        const rangeY = maxY - minY;
        // 计算 UV 坐标并归一化到 0 到 1 之间
        const uvs = [];
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          const u = (x - minX) / rangeX;
          const v = (y - minY) / rangeY;

          uvs.push(u, v);
        }

        geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));

        const material = new THREE.ShaderMaterial({
          uniforms: {
            texturetooth: { value: texture },
          },
          vertexShader: vertexShader,
          fragmentShader: fragmentShader,
          transparent:true,
        });
        const mesh = new THREE.Mesh(geometry, material);
        setModelPosition(prev => [...prev,mesh.position]);

        const bbox = new THREE.Box3().setFromObject(mesh);
        const center = bbox.getCenter(new THREE.Vector3());

        mesh.geometry.translate(-center.x, -center.y, -center.z);
        mesh.position.add(center);
        mesh.position.copy(startPositions4[index]);
        meshRef.current[index] = mesh;

        const tween = new TWEEN.Tween(startPositions4[index])
      .to(endPosition4[index], 3000)
      .onUpdate(() => {
        mesh.position.copy(startPositions4[index]);
      })
      .onComplete(() => {
        setIsPlaying(false);
      });
     tweenRef.current.push(tween); // 将每个 Tween 对象添加到数组中
        group.add(mesh);

        const planeGeometry = new THREE.PlaneGeometry(1, 1);
        const textureUrl = "./img/Sn.png";
        textureLoader.load(textureUrl, (texture) => {
          const material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            side: THREE.DoubleSide,
          });
          const plane5 = new THREE.Mesh(planeGeometry, material);
          plane5.position.copy(center);
          plane5.rotation.y = Math.PI / -3;
          plane5.position.x += -2;
          plane5.position.z += 4;
          plane5.visible = false;

          group.add(plane5);

          figure.current.push({ plane: plane5 });
        });

        totalCenter4.add(center);
        centerPoints4.push(center);

        const loadModel = (path, identifier) => {
          loader.load(path, (geometry) => {
            const material = new THREE.MeshPhongMaterial({
              color: "LightCoral",
            });
            const currentModel = new THREE.Mesh(geometry, material);
            currentModel.position.copy(center);
            currentModel.visible = false;
            currentModel.rotation.y = Math.PI / -3.7;
            currentModel.position.x += -2;
            currentModel.position.z += 3;
            group.add(currentModel);
            setModels((prevModels) => [
              ...prevModels,
              { id: identifier, model: currentModel },
            ]);
          });
        };

        // 加载模型
        loadModel("./annex/a1.stl", "model1");
        loadModel("./annex/a3.stl", "model2");
        loadModel("./annex/b1.stl", "model3");
        loadModel("./annex/b4.stl", "model4");
      });
    });

    let endPoint5;
    let sprite5;
    let sprites5;
    Promise.all(
      Modeltooth4.map(
        (filePath4) => new Promise((resolve) => loader.load(filePath4, resolve))
      )
    ).then(() => {
      // 计算两个模型中心点之间的中心点
      const distanceCenter = new THREE.Vector3().lerpVectors(
        centerPoints4[0],
        centerPoints4[1],
        0.5
      );

      const extensionDistance = 8;
      const yOffset = -5;

      endPoint5 = distanceCenter
        .clone()
        .add(new THREE.Vector3(-4, yOffset, extensionDistance));

      const curve = new THREE.CatmullRomCurve3([
        distanceCenter.clone(),
        endPoint5.clone(),
      ]);

      // 创建曲线的几何体
      const curveGeometry = new THREE.BufferGeometry().setFromPoints(
        curve.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterial = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObject5 = new THREE.Line(curveGeometry, curveMaterial);
      curveObject5.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObject5);

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("./img/jia.png");
      const planeMaterial = new THREE.SpriteMaterial({ map: texture });
      const plane5 = new THREE.Sprite(planeMaterial);
      plane5.scale.set(2, 2, 1);
      plane5.position.copy(endPoint5);
      plane5.visible = false;
      planeClick9.add(plane5);

      // 创建起始点
      const startPoints = new THREE.Vector3(-15, -2, 36);
      // 设置延伸方向和距离
      const extensionDirections = new THREE.Vector3(-20, -10, 13).normalize(); // 向外延伸的方向
      const extensionDistances = 8; // 延伸的距离
      // 计算终点
      const endPointEnd5 = startPoints
        .clone()
        .add(extensionDirections.multiplyScalar(extensionDistances));
      // 创建 Catmull-Rom 曲线
      const curves = new THREE.CatmullRomCurve3([startPoints, endPointEnd5]);
      // 创建曲线的几何体
      const curveGeometrys = new THREE.BufferGeometry().setFromPoints(
        curves.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterials = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObjectlines5 = new THREE.Line(curveGeometrys, curveMaterials);
      curveObjectlines5.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObjectlines5);

      const textures = textureLoader.load("./img/jia.png");
      const planeMaterials = new THREE.SpriteMaterial({ map: textures });
      const planelines5 = new THREE.Sprite(planeMaterials);
      planelines5.scale.set(2, 2, 1);
      planelines5.position.copy(endPointEnd5);
      planelines5.visible = false;
      planeClick10.add(planelines5);

      planes.current.push({
        curveObject: curveObject5,
        plane: plane5,
        planeline1: planelines5,
        curveObjectline1: curveObjectlines5,
      });

      const spriteMaterial1 = new THREE.SpriteMaterial();
      sprite5 = new THREE.Sprite(spriteMaterial1);
      sprite5.position.copy(endPointEnd5);
      sprite5.visible = false;
      sprite5.position.y -= 3;
      sprite5.scale.set(10, 10, 1);
      scene.add(sprite5);

      const spriteMaterial2 = new THREE.SpriteMaterial();
      sprites5 = new THREE.Sprite(spriteMaterial2);
      sprites5.position.copy(endPoint5);
      sprites5.visible = false;
      sprites5.position.y -= 3;
      sprites5.scale.set(10, 10, 1);
      scene.add(sprites5);

      // 创建包含图标的 Canvas
      const canvas2 = document.createElement("canvas");
      const ctx = canvas2.getContext("2d");
      canvas2.width = 188;
      canvas2.height = 188;

      // 绘制灰色背景
      ctx.fillStyle = "rgba(220, 220, 220, 1)";
      ctx.fillRect(0, -10, 180, 80);

      // 加载图标到 Canvas
      const icon = new Image();
      icon.src = "./img/icons8-multiply-24.png";
      icon.onload = () => {
        // 调整图标大小并绘制到 Canvas
        ctx.drawImage(icon, 10, 10, 40, 40);

        // 设置文本样式
        ctx.font = "30px Arial";
        ctx.fillStyle = "Black";

        // 绘制文本
        ctx.fillText("0.1", 70, 40);

        const anotherImage = new Image();

        anotherImage.src = "./img/next.png";
        anotherImage.onload = () => {
          ctx.drawImage(anotherImage, 140, 36, 35, 20);

          const thirdImage = new Image();
          thirdImage.src = "./img/up.png";
          thirdImage.onload = () => {
            ctx.drawImage(thirdImage, 140, 8, 35, 20);

            // 将 Canvas 转换为 Three.js 纹理
            const texture = new THREE.CanvasTexture(canvas2);

            // 应用纹理到精灵材质
            spriteMaterial2.map = texture;
            spriteMaterial2.needsUpdate = true; // 标记纹理需要更新
            spriteMaterial1.map = texture;
            spriteMaterial1.needsUpdate = true; // 标记纹理需要更新
          };
        };
      };
    });

    //第六组模型
    let totalCenter5 = new THREE.Vector3();
    let centerPoints5 = []; //储存模型中心点位置

    Modeltooth5.forEach((filePath5,index) => {
      loader.load(filePath5, (geometry) => {
        const positions = geometry.getAttribute("position").array;
        // 找到模型顶点坐标的最小值和最大值
        let minX = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          minX = Math.min(minX, x);
          maxX = Math.max(maxX, x);
          minY = Math.min(minY, y);
          maxY = Math.max(maxY, y);
        }
        // 计算顶点坐标范围
        const rangeX = maxX - minX;
        const rangeY = maxY - minY;
        // 计算 UV 坐标并归一化到 0 到 1 之间
        const uvs = [];
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          const u = (x - minX) / rangeX;
          const v = (y - minY) / rangeY;

          uvs.push(u, v);
        }

        geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));

        const material = new THREE.ShaderMaterial({
          uniforms: {
            texturetooth: { value: texture },
          },
          vertexShader: vertexShader,
          fragmentShader: fragmentShader,
          transparent:true,
        });
        const mesh = new THREE.Mesh(geometry, material);
        setModelPosition(prev => [...prev,mesh.position]);

        const bbox = new THREE.Box3().setFromObject(mesh);
        const center = bbox.getCenter(new THREE.Vector3());

        mesh.geometry.translate(-center.x, -center.y, -center.z);
        mesh.position.add(center);
        mesh.position.copy(startPositions5[index]);
        meshRef.current[index] = mesh;

        const tween = new TWEEN.Tween(startPositions5[index])
      .to(endPosition5[index], 3000)
      .onUpdate(() => {
        mesh.position.copy(startPositions5[index]);
      })
      .onComplete(() => {
        setIsPlaying(false);
      });
     tweenRef.current.push(tween); // 将每个 Tween 对象添加到数组中
        group.add(mesh);

        const planeGeometry = new THREE.PlaneGeometry(1, 1);
        const textureUrl = "./img/Sn.png";
        textureLoader.load(textureUrl, (texture) => {
          const material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            side: THREE.DoubleSide,
          });
          const plane6 = new THREE.Mesh(planeGeometry, material);
          plane6.position.copy(center);
          plane6.rotation.y = Math.PI / -2;
          plane6.position.x += -4;
          plane6.position.z += 2;
          plane6.visible = false;
          group.add(plane6);

          figure.current.push({ plane: plane6 });
        });

        totalCenter5.add(center);
        centerPoints5.push(center);

        const loadModel = (path, identifier) => {
          loader.load(path, (geometry) => {
            const material = new THREE.MeshPhongMaterial({
              color: "LightCoral",
            });
            const currentModel = new THREE.Mesh(geometry, material);
            currentModel.position.copy(center);
            currentModel.visible = false;
            currentModel.rotation.y = Math.PI / -2;
            currentModel.position.x += -4;
            currentModel.position.z += 1.5;
            group.add(currentModel);
            setModels((prevModels) => [
              ...prevModels,
              { id: identifier, model: currentModel },
            ]);
          });
        };

        // 加载模型
        loadModel("./annex/a1.stl", "model1");
        loadModel("./annex/a3.stl", "model2");
        loadModel("./annex/b1.stl", "model3");
        loadModel("./annex/b4.stl", "model4");
      });
    });

    let endPoint6;
    let sprite6;
    let sprites6;
    Promise.all(
      Modeltooth5.map(
        (filePath5) => new Promise((resolve) => loader.load(filePath5, resolve))
      )
    ).then(() => {
      // 计算两个模型中心点之间的中心点
      const distanceCenter = new THREE.Vector3().lerpVectors(
        centerPoints5[0],
        centerPoints5[1],
        0.5
      );

      const extensionDistance = 3;
      const yOffset = -6;

      endPoint6 = distanceCenter
        .clone()
        .add(new THREE.Vector3(-8, yOffset, extensionDistance));

      const curve = new THREE.CatmullRomCurve3([
        distanceCenter.clone(),
        endPoint6.clone(),
      ]);

      // 创建曲线的几何体
      const curveGeometry = new THREE.BufferGeometry().setFromPoints(
        curve.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterial = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObject6 = new THREE.Line(curveGeometry, curveMaterial);
      curveObject6.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObject6);

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("./img/jia.png");
      const planeMaterial = new THREE.SpriteMaterial({ map: texture });
      const plane6 = new THREE.Sprite(planeMaterial);
      plane6.scale.set(2, 2, 1);
      plane6.position.copy(endPoint6);
      plane6.visible = false;
      planeClick11.add(plane6);

      // 创建起始点
      const startPoints = new THREE.Vector3(-23, -3, 23);
      // 设置延伸方向和距离
      const extensionDirections = new THREE.Vector3(-20, -10, 3).normalize(); // 向外延伸的方向
      const extensionDistances = 10; // 延伸的距离
      // 计算终点
      const endPointEnd6 = startPoints
        .clone()
        .add(extensionDirections.multiplyScalar(extensionDistances));
      // 创建 Catmull-Rom 曲线
      const curves = new THREE.CatmullRomCurve3([startPoints, endPointEnd6]);
      // 创建曲线的几何体
      const curveGeometrys = new THREE.BufferGeometry().setFromPoints(
        curves.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterials = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObjectlines6 = new THREE.Line(curveGeometrys, curveMaterials);
      curveObjectlines6.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObjectlines6);

      const textures = textureLoader.load("./img/jia.png");
      const planeMaterials = new THREE.SpriteMaterial({ map: textures });
      const planelines6 = new THREE.Sprite(planeMaterials);
      planelines6.scale.set(2, 2, 1);
      planelines6.position.copy(endPointEnd6);
      planelines6.visible = false;
      planeClick12.add(planelines6);

      planes.current.push({
        curveObject: curveObject6,
        plane: plane6,
        planeline1: planelines6,
        curveObjectline1: curveObjectlines6,
      });

      const spriteMaterial1 = new THREE.SpriteMaterial();
      sprite6 = new THREE.Sprite(spriteMaterial1);
      sprite6.position.copy(endPointEnd6);
      sprite6.visible = false;
      sprite6.position.y -= 3;
      sprite6.scale.set(10, 10, 1);
      scene.add(sprite6);

      const spriteMaterial2 = new THREE.SpriteMaterial();
      sprites6 = new THREE.Sprite(spriteMaterial2);
      sprites6.position.copy(endPoint6);
      sprites6.visible = false;
      sprites6.position.y -= 3;
      sprites6.scale.set(10, 10, 1);
      scene.add(sprites6);

      // 创建包含图标的 Canvas
      const canvas2 = document.createElement("canvas");
      const ctx = canvas2.getContext("2d");
      canvas2.width = 188;
      canvas2.height = 188;

      // 绘制灰色背景
      ctx.fillStyle = "rgba(220, 220, 220, 1)";
      ctx.fillRect(0, -10, 180, 80);

      // 加载图标到 Canvas
      const icon = new Image();
      icon.src = "./img/icons8-multiply-24.png";
      icon.onload = () => {
        // 调整图标大小并绘制到 Canvas
        ctx.drawImage(icon, 10, 10, 40, 40);

        // 设置文本样式
        ctx.font = "30px Arial";
        ctx.fillStyle = "Black";

        // 绘制文本
        ctx.fillText("0.1", 70, 40);

        const anotherImage = new Image();

        anotherImage.src = "./img/next.png";
        anotherImage.onload = () => {
          ctx.drawImage(anotherImage, 140, 36, 35, 20);

          const thirdImage = new Image();
          thirdImage.src = "./img/up.png";
          thirdImage.onload = () => {
            ctx.drawImage(thirdImage, 140, 8, 35, 20);

            // 将 Canvas 转换为 Three.js 纹理
            const texture = new THREE.CanvasTexture(canvas2);

            // 应用纹理到精灵材质
            spriteMaterial2.map = texture;
            spriteMaterial2.needsUpdate = true; // 标记纹理需要更新
            spriteMaterial1.map = texture;
            spriteMaterial1.needsUpdate = true; // 标记纹理需要更新
          };
        };
      };
    });

    //第七组模型
    let totalCenter6 = new THREE.Vector3();
    let centerPoints6 = [];

    Modeltooth6.forEach((filePath6,index) => {
      loader.load(filePath6, (geometry) => {
        const positions = geometry.getAttribute("position").array;
        // 找到模型顶点坐标的最小值和最大值
        let minX = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          minX = Math.min(minX, x);
          maxX = Math.max(maxX, x);
          minY = Math.min(minY, y);
          maxY = Math.max(maxY, y);
        }
        // 计算顶点坐标范围
        const rangeX = maxX - minX;
        const rangeY = maxY - minY;
        // 计算 UV 坐标并归一化到 0 到 1 之间
        const uvs = [];
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          const u = (x - minX) / rangeX;
          const v = (y - minY) / rangeY;

          uvs.push(u, v);
        }

        geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));

        const material = new THREE.ShaderMaterial({
          uniforms: {
            texturetooth: { value: texture },
          },
          vertexShader: vertexShader,
          fragmentShader: fragmentShader,
          transparent:true,
        });
        const mesh = new THREE.Mesh(geometry, material);
        setModelPosition(prev => [...prev,mesh.position]);

        const bbox = new THREE.Box3().setFromObject(mesh);
        const center = bbox.getCenter(new THREE.Vector3());

        mesh.geometry.translate(-center.x, -center.y, -center.z);
        mesh.position.add(center);
        mesh.position.copy(startPositions6[index]);
        meshRef.current[index] = mesh;

        const tween = new TWEEN.Tween(startPositions6[index])
      .to(endPosition6[index], 3000)
      .onUpdate(() => {
        mesh.position.copy(startPositions6[index]);
      })
      .onComplete(() => {
        setIsPlaying(false);
      });
     tweenRef.current.push(tween); // 将每个 Tween 对象添加到数组中
        group.add(mesh);

        const planeGeometry = new THREE.PlaneGeometry(1, 1);
        const textureUrl = "./img/Sn.png";
        textureLoader.load(textureUrl, (texture) => {
          const material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            side: THREE.DoubleSide,
          });
          const plane7 = new THREE.Mesh(planeGeometry, material);
          plane7.position.copy(center);
          plane7.rotation.y = Math.PI / -2;
          plane7.position.x += -5.4;
          plane7.position.z += 2;
          plane7.visible = false;
          group.add(plane7);

          figure.current.push({ plane: plane7 });
        });

        totalCenter6.add(center);
        centerPoints6.push(center);

        const loadModel = (path, identifier) => {
          loader.load(path, (geometry) => {
            const material = new THREE.MeshPhongMaterial({
              color: "LightCoral",
            });
            const currentModel = new THREE.Mesh(geometry, material);
            currentModel.position.copy(center);
            currentModel.visible = false;
            currentModel.rotation.y = Math.PI / -2;
            currentModel.position.x += -5;
            currentModel.position.z += 1;
            group.add(currentModel);
            setModels((prevModels) => [
              ...prevModels,
              { id: identifier, model: currentModel },
            ]);
          });
        };

        // 加载模型
        loadModel("./annex/a1.stl", "model1");
        loadModel("./annex/a3.stl", "model2");
        loadModel("./annex/b1.stl", "model3");
        loadModel("./annex/b4.stl", "model4");
      });
    });

    let endPoint7;
    let sprites7;
    Promise.all(
      Modeltooth6.map(
        (filePath6) => new Promise((resolve) => loader.load(filePath6, resolve))
      )
    ).then(() => {
      // 计算两个模型中心点之间的中心点
      const distanceCenter = new THREE.Vector3().lerpVectors(
        centerPoints6[0],
        centerPoints6[1],
        0.5
      );

      const extensionDistance = 0;
      const yOffset = -5;

      endPoint7 = distanceCenter
        .clone()
        .add(new THREE.Vector3(-12, yOffset, extensionDistance));

      const curve = new THREE.CatmullRomCurve3([
        distanceCenter.clone(),
        endPoint7.clone(),
      ]);

      // 创建曲线的几何体
      const curveGeometry = new THREE.BufferGeometry().setFromPoints(
        curve.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterial = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObject7 = new THREE.Line(curveGeometry, curveMaterial);
      curveObject7.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObject7);

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("./img/jia.png");
      const planeMaterial = new THREE.SpriteMaterial({ map: texture });
      const plane7 = new THREE.Sprite(planeMaterial);
      plane7.scale.set(2, 2, 1);
      plane7.position.copy(endPoint7);
      plane7.visible = false;
      planeClick13.add(plane7);

      // 创建曲线的几何体
      const curveGeometry1 = new THREE.BufferGeometry().setFromPoints(
        curve.getPoints(100)
      );
      // 创建曲线的材质
      const curveMaterial1 = new THREE.LineBasicMaterial({ color: "DarkGray" });
      // 创建曲线对象
      const curveObjectlines7 = new THREE.Line(curveGeometry1, curveMaterial1);
      curveObjectlines7.visible = false;
      // 将曲线对象添加到场景中
      scene.add(curveObjectlines7);

      const textures = textureLoader.load("./img/jia.png");
      const planeMaterials = new THREE.SpriteMaterial({ map: textures });
      const planelines7 = new THREE.Sprite(planeMaterials);
      planelines7.scale.set(2, 2, 1);
      planelines7.position.copy(endPoint7);
      planelines7.visible = false;
      planeClick13.add(planelines7);

      planes.current.push({
        curveObject: curveObject7,
        plane: plane7,
        planeline1: planelines7,
        curveObjectline1: curveObjectlines7,
      });

      const spriteMaterial2 = new THREE.SpriteMaterial();
      sprites7 = new THREE.Sprite(spriteMaterial2);
      sprites7.position.copy(endPoint7);
      sprites7.visible = false;
      sprites7.position.y -= 3;
      sprites7.position.z += 1;
      sprites7.scale.set(10, 10, 1);
      scene.add(sprites7);

      // 创建包含图标的 Canvas
      const canvas2 = document.createElement("canvas");
      const ctx = canvas2.getContext("2d");
      canvas2.width = 188;
      canvas2.height = 188;

      // 绘制灰色背景
      ctx.fillStyle = "rgba(220, 220, 220, 1)";
      ctx.fillRect(0, -10, 180, 80);

      // 加载图标到 Canvas
      const icon = new Image();
      icon.src = "./img/icons8-multiply-24.png";
      icon.onload = () => {
        // 调整图标大小并绘制到 Canvas
        ctx.drawImage(icon, 10, 10, 40, 40);

        // 设置文本样式
        ctx.font = "30px Arial";
        ctx.fillStyle = "Black";

        // 绘制文本
        ctx.fillText("0.1", 70, 40);

        const anotherImage = new Image();

        anotherImage.src = "./img/next.png";
        anotherImage.onload = () => {
          ctx.drawImage(anotherImage, 140, 36, 35, 20);

          const thirdImage = new Image();
          thirdImage.src = "./img/up.png";
          thirdImage.onload = () => {
            ctx.drawImage(thirdImage, 140, 8, 35, 20);

            // 将 Canvas 转换为 Three.js 纹理
            const texture = new THREE.CanvasTexture(canvas2);

            // 应用纹理到精灵材质
            spriteMaterial2.map = texture;
            spriteMaterial2.needsUpdate = true; // 标记纹理需要更新新
          };
        };
      };
    });

    // 定义控制点
    const startPoint = new THREE.Vector3(1, 7, -5);
    const startPoint1 = new THREE.Vector3(1, 4, 3);
    const midPoint = new THREE.Vector3(1, 2, 5);
    const midPoint1 = new THREE.Vector3(1, -2, 5);
    const endPointl = new THREE.Vector3(1, -4, 3);
    const endPoints = new THREE.Vector3(1, -7, -5);

    // 创建曲线
    const curvePoints = [
      startPoint,
      startPoint1,
      midPoint,
      midPoint1,
      endPointl,
      endPoints,
    ];
    const curve = new THREE.CatmullRomCurve3(curvePoints);

    const tubeRadius = 0.07;
    const radialSegments = 16;

    const tubeGeometry = new THREE.TubeGeometry(
      curve,
      64,
      tubeRadius,
      radialSegments
    );
    const material = new THREE.MeshLambertMaterial({
      color: 0xff0000,
      depthTest: false,
    });
    const tubeMesh = new THREE.Mesh(tubeGeometry, material);

    tubeMesh.rotation.z = Math.PI / 2;
    tubeMesh.position.z = 20;
    tubeMesh.renderOrder = 1;
    tubeMesh.visible = false;
    tubeMesh.scale.set(4, 4, 4);

    scene.add(tubeMesh);

    curves.current = tubeMesh;

    //渲染牙龈

    loader.load(modelstl, (geometry) => {
      const positions = geometry.getAttribute("position").array;
      // 找到模型顶点坐标的最小值和最大值
      let minX = Number.POSITIVE_INFINITY;
      let maxX = Number.NEGATIVE_INFINITY;
      let minY = Number.POSITIVE_INFINITY;
      let maxY = Number.NEGATIVE_INFINITY;
      for (let i = 0; i < positions.length; i += 3) {
        const x = positions[i];
        const y = positions[i + 1];

        minX = Math.min(minX, x);
        maxX = Math.max(maxX, x);
        minY = Math.min(minY, y);
        maxY = Math.max(maxY, y);
      }
      // 计算顶点坐标范围
      const rangeX = maxX - minX;
      const rangeY = maxY - minY;
      // 计算 UV 坐标并归一化到 0 到 1 之间
      const uvs = [];
      for (let i = 0; i < positions.length; i += 3) {
        const x = positions[i];
        const y = positions[i + 1];

        const u = (x - minX) / rangeX;
        const v = (y - minY) / rangeY;

        uvs.push(u, v);
      }
      geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));
      const material = new THREE.ShaderMaterial({
        uniforms: {
          textureGiniva: { value: textureG },
          normalGiniva: { value: normaG },
          opacity: { value: 1.0 },
        },
        vertexShader: vertexShader1,
        fragmentShader: fragmentShader1,
        transparent:true,
      });
      const meshlower = new THREE.Mesh(geometry, material);
      modelRef.current.push(meshlower);
      meshlower.renderOrder = 0.1; // 设置透明模型的渲染顺序
      material.depthTest = true; // 确保透明材质启用深度测试


      groupstl.add(meshlower);
    });
    // })

    //监听隐藏牙龈的按钮
    const toggleButton = document.getElementById("toggleButton");
    let isModelVisible = true;
    //设置牙龈显示与隐藏
    toggleButton.addEventListener("click", () => {
      isModelVisible = !isModelVisible;
      if (isModelVisible) {
        scene.add(groupstl);
      } else {
        scene.remove(groupstl);
      }
    });

    // 创建牙齿着色器代码
    const vertexShader = `
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vViewPosition;
    
    void main(){
      
      vUv = uv;
      vUv.y = 1.0 - vUv.y; 
      vNormal = normalMatrix * normal;
      vec4 viewPosition = modelViewMatrix * vec4(position, 1.0);
      vViewPosition = -viewPosition.xyz;
      gl_Position = projectionMatrix * viewPosition;
    }`;

    const fragmentShader = `
    uniform sampler2D texturetooth;
    uniform float opacity;
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vViewPosition;

    void main(){
      vec4 textureColor = texture2D(texturetooth, vUv + 0.1);

      // 光照参数
      vec3 lightColor = vec3(0.7, 0.7, 0.7); // 光源颜色
      vec3 lightDirection = normalize(vec3(0.0, 0.0, 1.0)); // 光线方向

      float ambientIntensity = 0.3;
      float diffuseIntensity = max(dot(vNormal, lightDirection), 0.3);
      vec3 diffuseReflection = lightColor * diffuseIntensity;

      vec3 viewDirection = normalize(vViewPosition);
      vec3 reflectDirection = reflect(-lightDirection, vNormal);
      float specularIntensity = pow(max(dot(viewDirection, reflectDirection), 0.0), 32.0);
      vec3 specularReflection = lightColor * specularIntensity;

      vec3 finalColor = (ambientIntensity * textureColor.rgb) + (diffuseReflection * textureColor.rgb) + (specularReflection * textureColor.rgb);

      gl_FragColor = vec4(finalColor, textureColor.a * opacity );
    }`;

    // 创建牙齿着色器代码
    const vertexShaderclick = `
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vViewPosition;
    
    void main(){
      
      vUv = uv;
      vUv.y = 1.0 - vUv.y; 
      vNormal = normalMatrix * normal;
      vec4 viewPosition = modelViewMatrix * vec4(position, 1.0);
      vViewPosition = -viewPosition.xyz;
      gl_Position = projectionMatrix * viewPosition;
    }`;

    const fragmentShaderclick = `
    uniform float opacity;
    uniform sampler2D texturetooth;
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vViewPosition;

    void main(){
      vec4 textureColor = texture2D(texturetooth, vUv + 0.1);

      // 光照参数
      vec3 lightColor = vec3(0.7, 0.7, 0.7); // 光源颜色
      vec3 lightDirection = normalize(vec3(0.0, 0.0, 1.0)); // 光线方向

      float ambientIntensity = 0.3;
      float diffuseIntensity = max(dot(vNormal, lightDirection), 0.3);
      float alpha = opacity;
      vec3 diffuseReflection = lightColor * diffuseIntensity;

      vec3 viewDirection = normalize(vViewPosition);
      vec3 reflectDirection = reflect(-lightDirection, vNormal);
      float specularIntensity = pow(max(dot(viewDirection, reflectDirection), 0.0), 32.0);
      vec3 specularReflection = lightColor * specularIntensity;

      vec3 finalColor = (ambientIntensity * textureColor.rgb) + (diffuseReflection * textureColor.rgb) + (specularReflection * textureColor.rgb);

      gl_FragColor = vec4(finalColor, alpha);
    }`;

    // 创建牙龈着色器代码
    const vertexShader1 = `
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vViewPosition;

    void main(){
      vUv = uv;
      vNormal = normalMatrix * normal;
      vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
      vViewPosition = -mvPosition.xyz;
      gl_Position = projectionMatrix * mvPosition;
    }`;

    const fragmentShader1 = `
    uniform sampler2D textureGiniva;
    uniform sampler2D normalGiniva;

    uniform float opacity;
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vViewPosition;
    void main(){
  
      vec4 textureColor = texture2D(textureGiniva, vUv);

    // 从法线贴图中获取法线信息
    vec3 normalMap = texture2D(normalGiniva, vUv).rgb * 2.0 - 1.0;
    vec3 normalFromMap = normalize(normalMap);

    // 使用法线贴图中的法线信息替代模型本身的法线
    vec3 finalNormal = mix(vNormal, normalFromMap, 0.1); // 这里用 0.5 来平滑过渡

    // 光照计算
    vec3 ambientLight = vec3(0.6, 0.6, 0.6);
    vec3 lightColor = vec3(0.7, 0.7, 0.7);
    vec3 lightDirection = normalize(vec3(0.0, 0.0, 1.0));

    float ambientIntensity = 0.6;
    float diffuseIntensity = max(dot(finalNormal, lightDirection), 0.0);

    vec3 viewDirection = normalize(vViewPosition);
    vec3 reflectDirection = reflect(-lightDirection, finalNormal);
    float specularIntensity = pow(max(dot(viewDirection, reflectDirection), 0.0), 32.0);

    vec3 ambientReflection = ambientLight * ambientIntensity;
    vec3 diffuseReflection = lightColor * diffuseIntensity;
    vec3 specularReflection = lightColor * specularIntensity;

    vec3 finalColor = textureColor.rgb * (ambientReflection + diffuseReflection + specularReflection);

    gl_FragColor = vec4(finalColor, textureColor.a * opacity);
    }`;

    // 加载牙齿贴图
    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load(
      "./lower/52ec610d2c388ddc9c07f02027231f1.png"
    ); //牙齿纹理
    const textureG = textureLoader.load(
      "./lower/cbbc8c5d32d04fdcc90e6d05f84395a.png"
    ); //牙龈纹理
    const normaG = textureLoader.load(
      "./lower/3213a07d5afbd469dd84ff6fb4f3480.png"
    ); //牙龈法线
    /*----------------------------------------------------=>渲染模型分割线<=---------------------------------------------------------------------*/

    //鼠标右键创建小球
    let firstSphere = null; //用于储存上颌小球的引用
    let firstSphere1 = null; //用于存储下颌小球的引用
    let ctrlKey = false; //用于监听是否按下ctrl
    //监听ctrl按下
    document.addEventListener("keydown", (event) => {
      if (event.key === "Control") {
        ctrlKey = true;
        //按下ctrl时，禁用鼠标右键缩放
        controls.mouseButtons.RIGHT = THREE.MOUSE.DOLLY_NONE;
      }
    });
    //监听ctrl松开
    document.addEventListener("keyup", (event) => {
      if (event.key === "Control") {
        ctrlKey = false;
        //松开ctrl取消对鼠标交互的禁用
        controls.mouseButtons.RIGHT = THREE.MOUSE.DOLLY;
      }
    });

    //监听清空全部facc按钮点击事件
    const deleteLastSphereButton1 = document.getElementById("delete");
    deleteLastSphereButton1.addEventListener("click", () => {
      //清空所有带有isSphere的数据
      deleteObjects(group);
      deleteObjects(group1);
    });

    //清空带有isSpher标记的数据函数
    function deleteObjects(container) {
      container.children = container.children.filter(
        (child) => !child.isSphere
      );
    }

    //右键创建FACC
    const onMouseDown = (event) => {
      //检查是否为右键点击事件、在画布上按下或为按下ctrl键
      if (event.button === 2 && event.target === canvas && !event.ctrlKey) {
        //处理下颌和上颌小球的创建逻辑
        const mouse = new THREE.Vector2();
        mouse.x = (event.offsetX / canvas.clientWidth) * 2 - 1;
        mouse.y = -(event.offsetY / canvas.clientHeight) * 2 + 1;
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);
        const intersectslower = raycaster.intersectObjects([], true); //下颌
        // const intersectsupper = raycaster.intersectObjects([group1], true);//上颌

        // // 创建上颌小球
        // if (intersectsupper.length > 0) {
        //   const intersect1 = intersectsupper[0];
        //   const point = intersect1.point;
        //   //创建小球
        //   const geometry = new THREE.SphereGeometry(0.5, 32, 32);
        //   const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        //   const sphere2 = new THREE.Mesh(geometry, material);
        //   sphere2.position.copy(point);
        //   sphere2.isSphere = true;
        //   group1.add(sphere2);
        //   const intersectupper = raycaster.intersectObjects([group1], true);

        //   if (intersectupper.length > 0) {
        //     const point = intersectupper[0].point;

        //     if (!firstSphere) {
        //       // 创建第一个小球
        //       const geometry = new THREE.SphereGeometry(0.5, 32, 32);
        //       const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        //       firstSphere = new THREE.Mesh(geometry, material);
        //       firstSphere.position.copy(point);
        //       firstSphere.isSphere = true;
        //       group1.add(firstSphere);

        //     } else {
        //       // 创建第二个小球
        //       const geometry = new THREE.SphereGeometry(0.5, 32, 32);
        //       const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        //       const secondSphere = new THREE.Mesh(geometry, material);
        //       secondSphere.position.copy(point);
        //       secondSphere.isSphere = true;
        //       group1.add(secondSphere);

        //       // 创建曲线连接两个小球
        //       const curveGeometry = new THREE.BufferGeometry().setFromPoints([firstSphere.position, secondSphere.position]);
        //       const curveMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00 ,depthTest: false,depthWrite: false  });// 禁止深度缓冲
        //       const curveObject = new THREE.Line(curveGeometry, curveMaterial);
        //       curveObject.isSphere = true;
        //       group1.add(curveObject);

        //       firstSphere = null; // 连接完成后重置第一个小球
        //     }
        //   }
        // }
        // 创建下颌小球
        if (intersectslower.length > 0) {
          const intersect = intersectslower[0];
          const point = intersect.point;
          const geometry = new THREE.SphereGeometry(0.5, 32, 32);
          const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
          const sphere = new THREE.Mesh(geometry, material);
          sphere.position.copy(point);
          sphere.isSphere = true;
          group.add(sphere);
          const intersectlower = raycaster.intersectObjects([group], true);
          if (intersectslower.length > 0) {
            const point = intersectlower[0].point;
            if (!firstSphere1) {
              // 创建第一个小球
              const geometry = new THREE.SphereGeometry(0.5, 32, 32);
              const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
              firstSphere1 = new THREE.Mesh(geometry, material);
              firstSphere1.position.copy(point);
              firstSphere1.isSphere = true;
              group.add(firstSphere1);
            } else {
              // 创建第二个小球
              const geometry = new THREE.SphereGeometry(0.5, 32, 32);
              const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
              const secondSphere = new THREE.Mesh(geometry, material);
              secondSphere.position.copy(point);
              secondSphere.isSphere = true;
              group.add(secondSphere);

              // 创建曲线连接两个小球
              const curveGeometry = new THREE.BufferGeometry().setFromPoints([
                firstSphere1.position,
                secondSphere.position,
              ]);
              const curveMaterial = new THREE.LineBasicMaterial({
                color: 0x00ff00,
                depthTest: false, // 禁用深度测试
                depthWrite: false, // 禁止写入深度缓冲
              });
              const curveObject = new THREE.Line(curveGeometry, curveMaterial);
              curveObject.isSphere = true;
              group.add(curveObject);
              firstSphere1 = null; // 连接完成后重置第一个小球
            }
          }
        }
      }
    };

    // 生成facc按钮元素
    const generateArrowButton = document.getElementById("generateArrowButton");
    generateArrowButton.addEventListener("click", () => {
      generateArrowBetweenSpheres(group);
      // generateArrowTowardsNegativeY(group1);
    });

    // 生成下颌箭头函数
    const generateArrowBetweenSpheres = (group) => {
      // 循环遍历每个连接线
      group.children.forEach((child) => {
        if (child instanceof THREE.Line) {
          // 获取连接线的端点
          const points = child.geometry.attributes.position;
          const startPoint = new THREE.Vector3(
            points.getX(0),
            points.getY(0),
            points.getZ(0)
          );
          const endPoint = new THREE.Vector3(
            points.getX(1),
            points.getY(1),
            points.getZ(1)
          );
          // 计算中点位置
          const midpoint = new THREE.Vector3()
            .addVectors(startPoint, endPoint)
            .multiplyScalar(0.5);
          // 设置箭头初始位置在负方向
          const arrowPosition = new THREE.Vector3(
            midpoint.x,
            midpoint.y - 7,
            midpoint.z
          );
          // 设置箭头指向 (0, 1, 0)
          const direction = new THREE.Vector3(0, 1, 0);
          const arrowLength = 19;
          const arrow = new THREE.ArrowHelper(
            direction,
            arrowPosition,
            arrowLength
          );
          arrow.isSphere = true;
          group.add(arrow);
        }
      });
    };
    //生成上颌箭头
    // const generateArrowTowardsNegativeY = (group1) => {
    //   group1.children.forEach(child => {
    //     if (child instanceof THREE.Line) {
    //     const points = child.geometry.attributes.position;
    //     const startPoint = new THREE.Vector3(points.getX(0), points.getY(0), points.getZ(0));
    //     const endPoint = new THREE.Vector3(points.getX(1), points.getY(1), points.getZ(1));
    //     const midpoint = new THREE.Vector3().addVectors(startPoint, endPoint).multiplyScalar(0.5);
    //     const arrowPosition = new THREE.Vector3(midpoint.x, midpoint.y + 7, midpoint.z);
    //     // 设置箭头指向 (0, -1, 0)
    //     const direction = new THREE.Vector3(0, -1, 0);
    //     const arrowLength = 19;
    //     const arrow = new THREE.ArrowHelper(direction, arrowPosition, arrowLength);
    //     arrow.isSphere = true;
    //     group1.add(arrow);
    //     }
    //   });
    // };

    let sphere; //下颌小球
    let sphere2; //上颌小球
    let isDragging = false; //鼠标
    let youModeldata = { upper: {}, lower: {} }; //储存小球数据

    //鼠标交互模型生成小球
    const onMouseUp = (event) => {
      //检查点击的模型
      if (!isDragging && event.button === 0 && event.target === canvas) {
        event.preventDefault();
        const mouse = new THREE.Vector2();
        mouse.x = (event.offsetX / canvas.clientWidth) * 2 - 1;
        mouse.y = -(event.offsetY / canvas.clientHeight) * 2 + 1;
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);
        // const intersects = raycaster.intersectObjects([], true);

        // 获取与射线相交的物体
        const intersects = raycaster.intersectObjects(group.children, true);

        if (intersects.length > 0) {
          const foundObject = intersects[0].object; // 找到被点击的物体

          // 取消之前选中的物体
          if (
            selectedObject &&
            foundObject !== selectedObject &&
            selectedObject.material &&
            transformControls &&
            transformControls1
          ) {
            selectedObject.material = originalMaterial;
            transformControls = transformControls.detach();
            transformControls1 = transformControls1.detach();
          }

          if (foundObject.material) {
            if (selectedObject === foundObject) {
              // 取消选中
              foundObject.material = originalMaterial;
              selectedObject = null;
              // 移除 TransformControls
              scene.remove(transformControls);
              scene.remove(transformControls1);
              transformControls.detach();
              transformControls1.detach();
            } else {
              // 更新选中的物体及其材质
              originalMaterial = foundObject.material;
              const newMaterial = new THREE.ShaderMaterial({
                uniforms: {
                  texturetooth: { value: texture },
                  opacity: { value: 0.8 },
                },
                vertexShader: vertexShaderclick,
                fragmentShader: fragmentShaderclick,
                transparent: true,
              });
              foundObject.material = newMaterial;
              selectedObject = foundObject;

              // 创建 TransformControls箭头 实例
              transformControls = new TransformControls(
                camera,
                renderer.domElement
              );
              // 将选中的物体添加到 TransformControls 中
              transformControls.attach(foundObject);
              transformControls.addEventListener(
                "dragging-changed",
                function (event) {
                  controls.enabled = !event.value; // 当 TransformControls 处于拖动状态时禁用control鼠标控制器
                }
              );
              transformControls.setMode("translate");
              scene.add(transformControls);

              // 创建 TransformControls圆环 实例
              transformControls1 = new TransformControls(
                camera,
                renderer.domElement
              );
              transformControls1.attach(foundObject);

              const bbox = new THREE.Box3().setFromObject(foundObject);
              const center = bbox.getCenter(new THREE.Vector3());

              let currentAxis = ""; // 用于存储当前旋转轴方向
              const minRotation = -Math.PI / 3; // 最小旋转角度
              const maxRotation = Math.PI / 3; // 最大旋转角度

              transformControls1.addEventListener("change", function () {
                // 检查当前的旋转轴
                if (transformControls1.axis === "X") {
                  currentAxis = "Z";
                } else if (transformControls1.axis === "Y") {
                  currentAxis = "X";
                } else if (transformControls1.axis === "Z") {
                  currentAxis = "Y";
                }

                const rotation = foundObject.rotation;

                // 确保 x 轴旋转在范围内
                if (rotation.x < minRotation) {
                  rotation.x = minRotation;
                } else if (rotation.x > maxRotation) {
                  rotation.x = maxRotation;
                }

                // 确保 z 轴旋转在范围内
                if (rotation.z < minRotation) {
                  rotation.z = minRotation;
                } else if (rotation.z > maxRotation) {
                  rotation.z = maxRotation;
                }

                // 更新模型的旋转
                foundObject.rotation.copy(rotation);

                // 将 y 轴旋转设置为 0
                // foundObject.rotation.y = 0;
              });

              transformControls1.addEventListener(
                "dragging-changed",
                function (event) {
                  controls.enabled = !event.value;
                  if (event.value) {
                    transformControls.visible = false;
                    transformControls1.visible = false;

                    //创建并添加圆环
                    const RingGeometry = new THREE.TorusGeometry(
                      7,
                      0.2,
                      16,
                      100
                    );
                    const RingMaterial = new THREE.MeshPhongMaterial({
                      color: "LightYellow",
                    });
                    const RingMesh = new THREE.Mesh(RingGeometry, RingMaterial);

                    RingMesh.position.copy(center);

                    RingMesh.name = "ring";
                    // 根据当前旋转轴方向设置圆环的旋转
                    if (currentAxis === "X") {
                      RingMesh.rotation.y = Math.PI / 2;
                    } else if (currentAxis === "Y") {
                      RingMesh.rotation.x = Math.PI / 2;
                    } else if (currentAxis === "Z") {
                      // 默认情况下无需旋转
                    }
                    scene.add(RingMesh);
                  } else {
                    //拖动结束，显示控件并移除圆环
                    transformControls.visible = true;
                    transformControls1.visible = true;
                    const cubeToRemove = scene.getObjectByName("ring");
                    scene.remove(cubeToRemove);
                  }
                }
              );

              
              transformControls1.setMode("rotate");
              scene.add(transformControls1);
            }
          }
        } else if (selectedObject && originalMaterial !== null) {
          // 取消选中的物体
          selectedObject.material = originalMaterial;
          selectedObject = null;
        }

        

        const intersects1 = raycaster.intersectObjects([planeClick1]);
        const intersects2 = raycaster.intersectObjects([planeClick2]);
        const intersects3 = raycaster.intersectObjects([planeClick3]);
        const intersects4 = raycaster.intersectObjects([planeClick4]);
        const intersects5 = raycaster.intersectObjects([planeClick5]);
        const intersects6 = raycaster.intersectObjects([planeClick6]);
        const intersects7 = raycaster.intersectObjects([planeClick7]);
        const intersects8 = raycaster.intersectObjects([planeClick8]);
        const intersects9 = raycaster.intersectObjects([planeClick9]);
        const intersects10 = raycaster.intersectObjects([planeClick10]);
        const intersects11 = raycaster.intersectObjects([planeClick11]);
        const intersects12 = raycaster.intersectObjects([planeClick12]);
        const intersects13 = raycaster.intersectObjects([planeClick13]);

        // 创建一个数组，包含需要检查的对象
        const objectsToCheck = [
          sprites1,
          sprite1,
          sprites2,
          sprite2,
          sprites3,
          sprite3,
          sprites4,
          sprite4,
          sprites5,
          sprite5,
          sprites6,
          sprite6,
          sprites7,
        ];

        // 遍历数组，对每个对象执行相同的操作
        objectsToCheck.forEach((object, index) => {
          // 创建对应的 intersectsClick 变量，使用数组形式传入对象
          const intersectsClick = raycaster.intersectObjects([object]);
          // 根据 intersectsClick 的长度判断是否发生了交点，执行相应的操作
          if (intersectsClick.length > 0) {
            // 根据索引 index 找到对应的对象，并隐藏它
            objectsToCheck[index].visible = false;
          }
        });

        if (intersects1.length > 0) {
          // 显示对话框
          showDialog(event.clientX, event.clientY, "sprites1");
        } else if (intersects2.length > 0) {
          showDialog(event.clientX, event.clientY, "sprite1");
        } else if (intersects3.length > 0) {
          showDialog(event.clientX, event.clientY, "sprites2");
        } else if (intersects4.length > 0) {
          showDialog(event.clientX, event.clientY, "sprite2");
        } else if (intersects5.length > 0) {
          showDialog(event.clientX, event.clientY, "sprites3");
        } else if (intersects6.length > 0) {
          showDialog(event.clientX, event.clientY, "sprite3");
        } else if (intersects7.length > 0) {
          showDialog(event.clientX, event.clientY, "sprites4");
        } else if (intersects8.length > 0) {
          showDialog(event.clientX, event.clientY, "sprite4");
        } else if (intersects9.length > 0) {
          showDialog(event.clientX, event.clientY, "sprites5");
        } else if (intersects10.length > 0) {
          showDialog(event.clientX, event.clientY, "sprite5");
        } else if (intersects11.length > 0) {
          showDialog(event.clientX, event.clientY, "sprites6");
        } else if (intersects12.length > 0) {
          showDialog(event.clientX, event.clientY, "sprite6");
        } else if (intersects13.length > 0) {
          showDialog(event.clientX, event.clientY, "sprites7");
        } else if (intersects13.length > 0) {
          showDialog(event.clientX, event.clientY, "sprites7");
        } else {
          // 隐藏对话框
          hideDialog();
        }
        // const intersects1 = raycaster.intersectObjects([group1], true);

        //创建上颌小球
        // if(intersects1.length > 0){
        //   const intersect1 = intersects1[0];
        //   const point = intersect1.point;

        //   const geometry = new THREE.SphereGeometry(0.5, 32, 32);
        //   const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        //   sphere2 = new THREE.Mesh(geometry, material);
        //   sphere2.position.copy(point);
        //   sphere2.isRemovable = true;
        //   group1.add(sphere2);
        //   const newSphereData = {
        //     [sphereCountRef.current]:[point.x,point.y,point.z]
        //   };
        //   // 递增小球序号
        //   sphereCountRef.current++;
        //   youModeldata.upper = {
        //     ...youModeldata.upper,
        //     ...newSphereData
        //   };
        //   const buttonsArray = buttonsRef.current.children;
        //   const newSphereId = Object.keys(newSphereData)[0];
        //   for (let i = 0; i < buttonsArray.length; i++) {
        //     const buttonId = buttonsArray[i].id;
        //     if (buttonId === newSphereId) {
        //       buttonsArray[i].style.backgroundColor = 'red';
        //       break; // 找到匹配的按钮后跳出循环
        //     }
        //   }
        // }

        //创建下颌小球
        // if (intersects.length > 0) {
        //   const intersect = intersects[0];
        //   const point = intersect.point;
        //   const geometry = new THREE.SphereGeometry(0.5, 32, 32);
        //   const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        //   sphere = new THREE.Mesh(geometry, material);
        //   sphere.position.copy(point);
        //   sphere.isRemovable = true;
        //   // scene.add(sphere);
        //   group.add(sphere);
        //   // 将小球的位置坐标保存在数组中
        //   const newSphereData1 = {
        //     [sphereCountRef1.current]: [point.x, point.y, point.z], // 使用递增的小球序号作为ID
        //   };

        //   // 递增小球序号
        //   sphereCountRef1.current++;
        //   // 将新小球数据添加到状态中
        //   youModeldata.lower = {
        //     ...youModeldata.lower,
        //     ...newSphereData1,
        //   };
        //   const buttonsArray = buttonsRef.current.children;
        //   const newSphereId = Object.keys(newSphereData1)[0];
        //   for (let i = 0; i < buttonsArray.length; i++) {
        //     const buttonId = buttonsArray[i].id;
        //     if (buttonId === newSphereId) {
        //       buttonsArray[i].style.backgroundColor = "red";
        //       break; // 找到匹配的按钮后跳出循环
        //     }
        //   }
        // }
      }
    };
    //判断鼠标移动
    const onMouseMove = (event) => {
      if (event.buttons === 1 && event.target === canvas) {
        isDragging = true;
      } else {
        isDragging = false;
      }
    };

    let selectedObject = null;
    let originalMaterial = null;
    let transformControls;
    let transformControls1;

    //监听鼠标按钮
    setYouModeldata(youModeldata);
    canvas.addEventListener("mousedown", onMouseDown);
    canvas.parentElement.addEventListener("mouseup", onMouseUp);
    canvas.parentElement.addEventListener("mousemove", onMouseMove);
    /*----------------------------------------------------=>生成小球分割线<=---------------------------------------------------------------------*/
    //将模型储存到切换模型函数并渲染
    scene.add(group);
    // scene.add(group1);
    scene.add(groupstl);
    scene.add(planeClick1);
    scene.add(planeClick2);
    scene.add(planeClick3);
    scene.add(planeClick4);
    scene.add(planeClick5);
    scene.add(planeClick6);
    scene.add(planeClick7);
    scene.add(planeClick8);
    scene.add(planeClick9);
    scene.add(planeClick10);
    scene.add(planeClick11);
    scene.add(planeClick12);
    scene.add(planeClick13);

    // //创建一个 TransformControls 实例(将相机 camera 和渲染器的 DOM 元素 renderer.domElement 作为参数传递进去)
    // const transformControl = new TransformControls(camera, renderer.domElement);

    // transformControl.addEventListener('dragging-changed', function (event) {
    //   controls.enabled = !event.value;
    // });
    // transformControl.setMode('translate');
    // transformControl.size = 1.0;
    // scene.add(transformControl);
    // transformControl.attach(group)

    /*----------------------------------------------------=>渲染分割线<=---------------------------------------------------------------------*/
    //删除按钮对小球的交互
    const clearButton = document.getElementById("clearButton");
    clearButton.addEventListener("click", () => {
      //移除lower中的数据
      const removableLowerSpheres = group.children.filter((child) => {
        return child instanceof THREE.Mesh && child.isRemovable;
      });
      removableLowerSpheres.forEach((sphere) => {
        group.remove(sphere);
      });
      youModeldata.lower = {};

      // //移除upper中的数据
      // const removableUpperSpheres = group1.children.filter(child => {
      //   return child instanceof THREE.Mesh && child.isRemovable;
      // });
      // removableUpperSpheres.forEach(sphere => {
      //   group1.remove(sphere);
      // });
      // youModeldata.upper ={};

      // 清除按钮颜色
      const buttonsArray = buttonsRef.current.children;
      for (let i = 0; i < buttonsArray.length; i++) {
        buttonsArray[i].style.backgroundColor = ""; // 清除按钮颜色
      }

      //重置小球序号
      sphereCountRef.current = 1;
      sphereCountRef1.current = 17;
    });

    /*----------------------------------------------------=>清空分割线<=---------------------------------------------------------------------*/

    //创建鼠标控制器
    const controls = new OrbitControls(camera, renderer.domElement);

    // 设置旋转速度
    controls.rotateSpeed = 0.3;
    controls.mouseButtons = {
      LEFT: THREE.MOUSE.ROTATE, //左键旋转
      MIDDLE: THREE.MOUSE.PAN, //中键平移
      RIGHT: THREE.MOUSE.DOLLY, //右键缩放
    };

    function onCamera(event) {
      const scale = event.deltaY > 0 ? 0.9 : 1.1;
      grid.scale.multiplyScalar(scale);
    }

    window.addEventListener("wheel", onCamera);

    //环境灯光
    const ambientLight = new THREE.AmbientLight(0xffffff, 1.0);
    scene.add(ambientLight);
    //点光灯光
    const light = new THREE.PointLight(0xffffff, 1.0);
    light.position.set(50, 50, 50);
    scene.add(light);
    //平行光
    const directioalLight = new THREE.DirectionalLight(0xffffff, 2.0);
    directioalLight.position.set(-30, 50, 150);
    scene.add(directioalLight);

    /*----------------------------------------------------=>交互分割线<=---------------------------------------------------------------------*/

    // 切换下颌视角
    function switchView() {
      camera.position.set(
        group.position.x,
        group.position.y + 85,
        group.position.z
      );
      camera.lookAt(group.position);
    }
    switchButtonRef.current.addEventListener("click", switchView);
    // 切换上颌视角
    function switchView1() {
      camera.position.set(
        group.position.x,
        group.position.y,
        group.position.z + 85
      );
      camera.lookAt(group.position);
    }
    switchButtonRef1.current.addEventListener("click", switchView1);

    /*----------------------------------------------------=>视角切换分割线<=---------------------------------------------------------------------*/

    // 显示第一个对话框
    const showDialog = (x, y, spriteName) => {
      const dialog = document.createElement("div");
      dialog.innerHTML = `
        <p data-option="添加切片">添加切片</p>
        <p data-option="添加间距">添加间距</p>
      `;
      // 对话框的样式设置
      dialog.style.position = "absolute";
      dialog.style.top = `${y}px`;
      dialog.style.left = `${x}px`;
      dialog.style.backgroundColor = "#fff";
      dialog.style.border = "2px solid #ccc";
      dialog.style.padding = "1px";
      dialog.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.5)";
      dialog.id = "dialog";

      // 对话框选项添加监听器
      const options = dialog.querySelectorAll("p");

      // 移入移出鼠标
      options.forEach((option) => {
        option.addEventListener("mouseover", () => {
          option.style.backgroundColor = "#e0e0e0";
        });

        option.addEventListener("mouseout", () => {
          option.style.backgroundColor = "#fff";
        });

        // 对话框选项点击
        option.addEventListener("click", () => {
          // 隐藏当前对话框
          dialog.remove();

          if (spriteName === "sprite1") {
            sprite1.visible = true;
          } else if (spriteName === "sprites1") {
            sprites1.visible = true;
          } else if (spriteName === "sprite2") {
            sprite2.visible = true;
          } else if (spriteName === "sprites2") {
            sprites2.visible = true;
          } else if (spriteName === "sprite3") {
            sprite3.visible = true;
          } else if (spriteName === "sprites3") {
            sprites3.visible = true;
          } else if (spriteName === "sprite4") {
            sprite4.visible = true;
          } else if (spriteName === "sprites4") {
            sprites4.visible = true;
          } else if (spriteName === "sprite5") {
            sprite5.visible = true;
          } else if (spriteName === "sprites5") {
            sprites5.visible = true;
          } else if (spriteName === "sprite6") {
            sprite6.visible = true;
          } else if (spriteName === "sprites6") {
            sprites6.visible = true;
          } else if (spriteName === "sprites7") {
            sprites7.visible = true;
          }
        });
      });

      // 添加到场景
      document.body.appendChild(dialog);
    };

    // 隐藏主对话框对话框
    const hideDialog = () => {
      const dialog = document.getElementById("dialog");
      if (dialog) {
        dialog.remove();
      }
    };

    // 创建一个函数用于处理窗口大小变化事件
    function onWindowResize() {
      const newWidth = window.innerWidth * 0.65;
      const newHeight = window.innerHeight * 0.8;
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(newWidth, newHeight);
    }

    // 监听窗口大小变化事件
    window.addEventListener("resize", onWindowResize, false);

    // 渲染场景
    function animate() {
      requestAnimationFrame(animate);
      controls.update();
      TWEEN.update();
      renderer.render(scene, camera);
    }

    animate();

    return () => {
      canvas.addEventListener("mousedown", onMouseDown);
      canvas.parentElement.addEventListener("mouseup", onMouseUp);
      canvas.parentElement.addEventListener("mousemove", onMouseMove);
    };
  }, []);

  //将小球json数据传递到后端
  const sendDataToBackend = () => {
    const dataToSend = {
      caseId: tem,
      ...youModeldata,
    };
    const jsonData = JSON.stringify(dataToSend);
    // json发送数据到后端
    fetch("http://104.168.22.222:3030/qiniu/json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonData,
    })
      .then((response) => response.json())
      .then((data) => {});
  };

  /*----------------------------------------------------=>保存json文件分割线<=---------------------------------------------------------------------*/

  // 处理点击跳过按钮的逻辑
  const handleSkip = () => {
    // 让球 ID 递增，跳过当前的 ID
    sphereCountRef.current++; //下颌
    sphereCountRef1.current++; //上颌
  };

  /*----------------------------------------------------=>小球分割线<=---------------------------------------------------------------------*/

  const toggleGrid = () => {
    if (grids.current) {
      grids.current.visible = !grids.current.visible;
    }
  };

  //牙弓线
  const toggCurve = () => {
    if (curves.current) {
      curves.current.visible = !curves.current.visible;
    }
  };

  // 点击时切换平面显示/隐藏状态
  const togglePlaneVisibility = () => {
    figure.current.forEach((group) => {
      group.plane.visible = !group.plane.visible;
    });
  };

  //点击切换曲线平面显示/隐藏
  const togglecurvePlane = () => {
    planes.current.forEach((group) => {
      console.log(group); // 输出当前处理的元素
      group.curveObject.visible = !group.curveObject.visible;
      group.plane.visible = !group.plane.visible;
      group.planeline1.visible = !group.planeline1.visible;
      group.curveObjectline1.visible = !group.curveObjectline1.visible;
    });
  };

  useEffect(() => {
    // 根据选择的选项显示或隐藏模型
    models.forEach(({ id, model }) => {
      model.visible = id === selectedOption;
    });
  }, [selectedOption, models]);

  function handleSelectChange(value) {
    setSelectedOption(value);
  }

  const togglePlayerVisibility = () => {
    setIsPlayerVisible(!isPlayerVisible); // 切换显示/隐藏状态
  };

  const handleOpacityChange = (value) => {
    modelRef.current.forEach((model) =>{
      if(model){
        model.material.uniforms.opacity.value = value / 100;
      }
    })
  }

  const handlePrintPosition = () => {
    const uniquePositionsMap = new Map(); // 使用 Map 来确保唯一性
      modelPosition.forEach(pos => {
        const key = `${pos.x},${pos.y},${pos.z}`;
        uniquePositionsMap.set(key, pos); // 使用坐标字符串作为键，确保唯一性
      });
      const uniquePositions = Array.from(uniquePositionsMap.values());
  
      uniquePositions.forEach((position, index) => {
        const x = position.x;
        const y = position.y;
        const z = position.z;
        console.log(`model ${index + 1}: x=${x},y=${y},z=${z}`);
      });
    fetch("http://localhost:3030/modelposition" , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ positions: uniquePositions })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('网络响应不正常');
      }
      return response.json();
    })
    .then(data => {
      console.log('以成功发生数据:', data);
    })
    .catch(error => {
      console.error('操作出现问题异常:', error);
    });
  }
  
  const handlePlayPause = () => {
    setIsPlaying(!isPlaying); // 切换播放状态

    // 控制每个模型的 Tween 动画
    tweenRef.current.forEach((tween) => {
      if (isPlaying) {
        tween.stop(); // 停止每个动画
      } else {
        tween.start(); // 启动每个动画
      }
    });
  };

  /*----------------------------------------------------=>隐藏、显示分割线<=---------------------------------------------------------------------*/

  return (
    <div>
      <Row>
        <Col span={19}>
          <div
            style={{
              width: "100%",
              paddingRight: "10%",
              alignItems: "flex-start",
            }}
          >
            {/* 正牙颌 */}
            <Button ref={switchButtonRef1}>
              <img
                src="./img/gingiva.png"
                alt="button-background"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Button>

            {/* 上牙颌 */}
            <Button>
              <img
                src="./img/toothupper.png"
                alt="button-background"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Button>

            {/* 下牙颌 */}
            <Button ref={switchButtonRef}>
              <img
                src="./img/toothlower.png"
                alt="button-background"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Button>

            {/* 网格 */}
            <Button onClick={toggleGrid}>
              <img
                src="./img/grid.png"
                alt="button-background"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  height: "100%",
                }}
              />
              {/* {showGrid } */}
            </Button>

            <Button id="toggleButton">
              <img
                src="./img/tooth.png"
                alt="button-background"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Button>

            {/* 曲线 */}
            <Button onClick={toggCurve}>
              <img
                src="./img/u.png"
                alt="button-background"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Button>

            <Button onClick={togglePlaneVisibility}>
              <img
                src="./img/plane.png"
                alt="button-background"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Button>

            <Button onClick={togglecurvePlane}>
              <img
                src="./img/_tipr_exit.png"
                alt="button-background"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Button>

            <Button onClick={togglePlayerVisibility}>
              <img
                src="./img/bf.png"
                alt="button-background"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Button>

            <span style={{ marginLeft: "20%" }}>病例号：{tem}</span>
            
          </div>

          <DialogComponent />
          
        </Col>
        <canvas
          id="canvas"
          ref={canvasRef}
          style={{ width: "100%", height: "100%" }}
        />

        <Sider
          theme="light"
          width={"380px"}
          style={{ background: "transparent" }}
        >
          <Col
            style={{
              position: "fixed",
              top: "10%",
              left: "80%",
              width: "380px",
            }}
          >
            <div ref={buttonsRef}>
              <h1 style={{ marginLeft: "150px" }}>上颌</h1>
              <button style={{ marginLeft: "130px" }} id="7">
                {" "}
                22
              </button>
              <button style={{ marginLeft: "0px" }} id="8">
                {" "}
                21
              </button>
              <button style={{ marginLeft: "0px" }} id="9">
                {" "}
                11
              </button>
              <button style={{ marginRight: "160px" }} id="10">
                {" "}
                12
              </button>
              <button style={{ marginLeft: "110px" }} id="6">
                {" "}
                23
              </button>
              <button
                style={{ marginLeft: "75px", marginRight: "120px" }}
                id="11"
              >
                {" "}
                13
              </button>
              <button style={{ marginLeft: "90px" }} id="5">
                {" "}
                24
              </button>
              <button
                style={{ marginLeft: "115px", marginRight: "100px" }}
                id="12"
              >
                {" "}
                14
              </button>
              <button style={{ marginLeft: "80px" }} id="4">
                {" "}
                25
              </button>
              <button
                style={{ marginLeft: "135px", marginRight: "100px" }}
                id="13"
              >
                15
              </button>
              <button style={{ marginLeft: "75px" }} id="3">
                26
              </button>
              <button
                style={{ marginLeft: "147px", marginRight: "100px" }}
                id="14"
              >
                16
              </button>
              <button style={{ marginLeft: "65px" }} id="2">
                27
              </button>
              <button
                style={{ marginLeft: "168px", marginRight: "100px" }}
                id="15"
              >
                17
              </button>
              <button style={{ marginLeft: "60px" }} id="1">
                28
              </button>
              <button style={{ marginLeft: "180px" }} id="16">
                18
              </button>
              <br></br>
              <br></br>
              <button style={{ marginLeft: "60px" }} id="32">
                {" "}
                48
              </button>
              <button
                style={{ marginLeft: "180px", marginRight: "85px" }}
                id="17"
              >
                {" "}
                38
              </button>
              <button style={{ marginLeft: "65px" }} id="31">
                {" "}
                47
              </button>
              <button
                style={{ marginLeft: "168px", marginRight: "100px" }}
                id="18"
              >
                {" "}
                37
              </button>
              <button style={{ marginLeft: "75px" }} id="30">
                {" "}
                46
              </button>
              <button
                style={{ marginLeft: "147px", marginRight: "100px" }}
                id="19"
              >
                {" "}
                36
              </button>
              <button style={{ marginLeft: "80px" }} id="29">
                {" "}
                45
              </button>
              <button
                style={{ marginLeft: "135px", marginRight: "100px" }}
                id="20"
              >
                {" "}
                35
              </button>
              <button style={{ marginLeft: "90px" }} id="28">
                {" "}
                44
              </button>
              <button
                style={{ marginLeft: "115px", marginRight: "100px" }}
                id="21"
              >
                {" "}
                34
              </button>
              <button style={{ marginLeft: "110px" }} id="27">
                {" "}
                43
              </button>
              <button
                style={{ marginLeft: "75px", marginRight: "150px" }}
                id="22"
              >
                {" "}
                33
              </button>
              <button style={{ marginLeft: "130px" }} id="26">
                {" "}
                42
              </button>
              <button id="25"> 41</button>
              <button id="24"> 31</button>
              <button id="23"> 32</button>
              <br></br>
              <h1 style={{ marginLeft: "150px" }}>下颌</h1>
            </div>
            <br></br>
            <div>
              <Button onClick={handleSkip} style={{ marginLeft: "20px" }}>
                下一个牙齿
              </Button>
              <Button style={{ marginLeft: "20px" }} id="clearButton">
                清除小球
              </Button>
              <br></br>
            </div>
            <br></br>
            <Button style={{ marginLeft: "20px" }} onClick={sendDataToBackend}>
              保存json数据
            </Button>
            <br />
            <br></br>
            <Button style={{ marginLeft: "20px" }} id="generateArrowButton">
              生成FACC
            </Button>
            <Button style={{ marginLeft: "20px" }} id="delete">
              清空Facc
            </Button>

            <Button onClick={handlePrintPosition}>打印模型位置</Button>
            

            <div>
              <Select
                style={{
                  marginTop: "20px",
                  marginLeft: "20px",
                  width: "250px",
                  height: "30px",
                }}
                onChange={handleSelectChange}
                value={selectedOption}
                defaultValue=""
              >
                <Option value="">请选择附件</Option>
                <Option value="model1">附件1</Option>
                <Option value="model2">附件2</Option>
                <Option value="model3">附件3</Option>
                <Option value="model4">附件4</Option>
              </Select>

              
            </div>

            <div style={{marginLeft:'30px',marginTop:'30px'}}>
              <p>透明度控制器</p>
            <TransparentController onOpacityChange={handleOpacityChange}/>
            </div>
            
          </Col>
        </Sider>
      </Row>

      <div>
        {isPlayerVisible && (
          <div style={{ width: "1540px" }}>
            <Player isPlaying={isPlaying} setIsPlaying={setIsPlaying} tweenRef={tweenRef.current}  />
          </div>
        )}
      </div>
      
    </div>
  );
};

export default Modelrender;
