
// vtk依赖
import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import vtkSTLReader from "@kitware/vtk.js/IO/Geometry/STLReader";
import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import { useEffect, useRef, useState } from 'react';


// 自定义hooks 用于读取STL文件并返回Actor对象
const useReaderSTL=()=>{
    const content = useRef(null);
    const [stlActor,setStlActor] = useState(null);
    useEffect(()=>{
        if(!content.current){
            const actor = vtkActor.newInstance();
            const mapper = vtkMapper.newInstance({ scalarVisibility: false });
            const reader = vtkSTLReader.newInstance();
            actor.setMapper(mapper);
            mapper.setInputConnection(reader.getOutputPort());
            content.current = {
                actor,
                mapper,
                reader,
            };
        }
    },[])

    // 提供给外部的读取函数
    function renderSTLFile(STLFile){
        const {reader,actor} = content.current;
        if (STLFile !== undefined) {
            if (STLFile !== null) {
                const fileReader = new FileReader();
                fileReader.onload = function (file) {
                    reader.parseAsArrayBuffer(fileReader.result);
                    // 读取后重新修改actor 
                    setStlActor(actor); 
            }
                fileReader.readAsArrayBuffer(STLFile);
            }
        }
    }
    // 将 actor和函数接口返回给外部
    return [stlActor,renderSTLFile];
}

export default useReaderSTL;