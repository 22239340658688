import React, { useState } from "react";
import axios from "axios";
//UI
import {Row, Col} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { get } from "@kitware/vtk.js/macros";
import { upload } from "@testing-library/user-event/dist/upload";

const FileUploadComponent = () => {
  const [files, setFiles] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);

    // 检查文件的大小是否符合要求
    const sizeLimit = 102400 * 102400;//100MB
  
    const invalidFiles = uploadedFiles.filter((file) => file.size > sizeLimit);

    if (invalidFiles.length > 0) {
      // 文件超过大小限制，显示错误信息
      const errorMsg = `以下文件超过了大小：${invalidFiles.map(
        (file) => file.name
      )}`;
      setErrorMsg(errorMsg);
      return;
    }

    // 更新文件列表
    setFiles([...files, ...uploadedFiles]);
    setErrorMsg("");
  };

  const handleFileRemove = (fileName) => {
    const updatedFiles = files.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
  };

  const handleSubmit = () => {
    // 将文件列表上传到服务器
  //   const props = {
  //     name: 'file',//name得看接口需求，name与接口需要的name一致
  //     action: '/test/upload/file',//接口路径
  //     data: {file} ,//接口需要的参数，无参数可以不写
  //     multiple: false,//支持多个文件
  //     showUploadList: true,//展示文件列表
  //     headers: {
  //       // "Content-Type": "multipart/form-data"
  //     },
  // }
      
    console.log(files);
  };

  return (
    <>
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <h1>版本更新</h1>
    </div>
    <div style={{background:'white',height:'500px',width:'400px'}}>
      <Row style={{padding:'20px'}}>
        <Col >
          <span style={{ fontWeight: 'bold' }}>上传更新:</span>
        </Col>
      </Row>
      <Row >
        <Col offset={2}> 
          <div>
            
            <input type="file" multiple onChange={handleFileUpload} props/>
            {errorMsg && <div>{errorMsg}</div>}
            <ul>
              {files.map((file, index) => (
                <li key={index}>
                  {file.name} ({(file.size / 1024).toFixed(2)} KB){" "}
                  <button onClick={() => handleFileRemove(file.name)}>删除</button>
                </li>
                ))}
            </ul>
            <button onClick={handleSubmit}>上传到服务器</button>
          </div>
        </Col>
      </Row>

    </div>
    </>
  );
};

export default FileUploadComponent;