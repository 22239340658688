import axios from 'axios';
import React, { useEffect,useState} from 'react';

function Foldermysql() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);

    //查询数据库
    const handleSearch = () => {
      const url = `http://104.168.22.222:3030/api/search?term=${searchTerm}`;
  
      axios.get(url)
        .then(response => {
          // 处理响应数据
          setSearchResults(response.data);
        })
        .catch(error => {
          // 处理错误
          console.error(error);
        });
    };
  
    useEffect(() => {
      // 发送GET请求获取文件夹列表
      axios.get('http://104.168.22.222:3030/api/search')
        .then(response => {
          setSearchResults(response.data);
        })
        .catch(error => {
        });
    }, []);
    
    const handleFileClick = (file) => {
        // 将文件信息发送到后端接口
        setSelectedFolder(file);
        axios.post('http://104.168.22.222:3030/api/upload', { file })
        .then(response => {
            console.log('文件已发送至后端接收');
        })
        .catch(error => {
            console.error('发送失败',error);
        });
    };
  
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    
    
    return (
      <>
      <div>
        <input
        type='text'
        value={searchTerm}
        onChange={handleInputChange}
        style={{height:'30px',width:'150px'}}
        />
        <button onClick={handleSearch} style={{height:'30px',width:'50px'}}>搜索</button>
        <ul style={{overflow: 'auto',height:'150px'}}>
          {searchResults.map(folder => (
            <li key={folder}
              onClick={() => handleFileClick(folder)}
              style={{ backgroundColor: selectedFolder === folder ? 'lightblue' : 'transparent' }}
            >{folder}</li>
          ))}
        </ul>
      </div>
      </>
    );
  }
  
  export default Foldermysql;