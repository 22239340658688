//react 组件
import React, { useState } from "react";

// 自定义组件
//import STLReader from "./components/VTK/vtkSTLReader";
import RenderWindows from "./components/VTK/renderWindows";
import FileDirectory from "./components/UI/FileDirectory";

// 自定义hooks
import useReaderSTL from "./hooks/useReaderSTL";

// antd UI 组件
import { Modal, Upload, message } from 'antd';
import { Button,  Menu } from 'antd';
import Sider from "antd/es/layout/Sider";
import { UploadOutlined} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";

// css
import './Worktable.css';

// 工作台路由组件
// 实现读取STL文件流程
// 首先通过Upload组件将获取STL文件对象
// 通过自定义hooks useReaderST renderSTLFile函数 将文件对象读取
// 再自定义hooks useReaderST中获取Actor
// 将Actor传入渲染组件renderWindows中
function Worktable() {
    const baseURL = 'http://127.0.0.1:8000/api/';

    // 使用自定义hooks实现读取STL文件
    const [stlActor,renderSTLFile] = useReaderSTL();
    // 菜单
    const [isUploadMenu,setIsUploadMenu] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // 全局消息
    const [messageApi, contextHolder] = message.useMessage();
    
    const props = {
      action: baseURL+'upload/',
      onChange({ file, fileList }) {
        if (file.status !== 'uploading') {
          renderSTLFile(file.originFileObj);
        } 
      },
    };
    

    const showModal = () => {
        setIsModalOpen(true);
        setIsUploadMenu(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsUploadMenu(false);
    };

    return(<>
     <Modal
        title="上传文件"
        centereds
        onCancel={handleCancel}
        // 如果有相同组件 一定要给一个key 不然会出现
        // 警告Warning: Each child in a list should have a unique "key" prop. 
        footer={[<Button key='Cancel' onClick={handleCancel}>Cancel</Button>]}
        open={isModalOpen}>
        <div className="Upload-demo">
        <form  style={{position:'fixed'}} method="post" encType="multipart/form-data">
            <Upload {...props}>
                    <Button key="Upload" icon={<UploadOutlined />}>上传文件</Button>
            </Upload>
        </form>
        </div>

    </Modal>
    <Sider theme="light">
       {contextHolder} 
        <FileDirectory/>
        <Menu mode="inline"
                items={[{
                    label: 'upload',
                    icon:<UploadOutlined />,
                    onClick:showModal,
                    disabled:isUploadMenu
                }]}>
        </Menu>
    </Sider>
    <Content>
        <div style={{margin:'10px '}}>
        <RenderWindows  actor = {stlActor}/>
        </div>
    </Content>
    </>)
}

export default Worktable;