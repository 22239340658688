// //react 组件

import ModelViewer from './componentsthree/Three/threestyle';
import FolderList from './componentsthree/UI/qiniuUtils';
import Foldermysql from './componentsthree/UI/Mysql';

import Sider from 'antd/es/layout/Sider';
import {Content} from "antd/es/layout/layout";
import './therr.css';

const Threejs = () => {

  return (
    <>
    <div className='container'>
        <Sider theme="light" 
        style={{background:'white',width:'100%',height:'100%'}}>
        <h2 style={{margin:'10px', textAlign:'center'}}>上传Three渲染</h2>
        <br></br>
        <h2 style={{textAlign:'center',margin:'20px'}}>病例文件</h2>
        <FolderList style={{margin:'10px'}}/>
        <Foldermysql/>
        <br></br>
        </Sider>
       
    </div>
     <div style={{width:'100%',height:'100%',margin:'10px'}}>
      

       {/* <div style={{width:'100%',height:'100%' }}> */}
         <ModelViewer  />
       {/* </div> */}
   </div>
   </>
  );
};

export default Threejs;