
import {Button} from 'antd';


const SkipButton1 = ({}) => {
   
  
  return (
       <p>这是一个点击事件</p>
        
      );
};

export default SkipButton1;