import React, { useState, useRef } from 'react';
import './ProgressBar.css'; // 导入样式文件

const Player = ({isPlaying, setIsPlaying, tweenRef}) => {

  const inputRef = useRef();
  const [progress, setProgress] = useState(0); // 用于跟踪进度的状态

  const handleMouseDown = () => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    updateProgress(e.clientX);
  };

  const handleClick = (e) => {
    updateProgress(e.clientX);
  };

  const handleImageChange = () => {
    if (isPlaying) {
      tweenRef.forEach((tween) => {
        tween.stop(); // 停止每个动画
      });
    } else {
      tweenRef.forEach((tween) => {
        tween.start(); // 启动每个动画
      });
    }
    setIsPlaying(!isPlaying); // 切换播放状态
    
  };

  const updateProgress = (clientX) => {
    const input = inputRef.current;
    if (input) {
      const rect = input.getBoundingClientRect();
      const offsetX = clientX - rect.left;
      const width = rect.width;
      const percentage = (offsetX / width) * 100;
      setProgress(percentage);
    }
  };

  

  return (
    <>
    <p style={{marginLeft:'165px'}}>
          <span style={{fontSize:'20px'}}>0</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'20px',marginLeft:'20px'}}>5</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'20px',marginLeft:'20px'}}>10</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'20px',marginLeft:'20px'}}>15</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'20px',marginLeft:'20px'}}>20</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'20px',marginLeft:'20px'}}>25</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'20px',marginLeft:'20px'}}>30</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'20px',marginLeft:'20px'}}>35</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'20px',marginLeft:'20px'}}>40</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'20px',marginLeft:'20px'}}>45</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'5px',color:'gray',marginLeft:'20px'}}>▋</span>
          <span style={{fontSize:'20px',marginLeft:'20px'}}>50</span>
        </p>
    <div className="progress-bar-container">
    <button className="circle-button" ><img src='./img/bf1.png' style={{width:'15px',height:'15px'}}/></button>
    <button className="circle-button" style={{marginLeft:'10px'}}><img src='./img/bf3.png' style={{width:'15px',height:'15px'}}/></button>
    <button className="circle-button1" onClick={handleImageChange} style={{marginLeft:'10px'}}>
      <img src={isPlaying ? './img/bf5.png' : './img/bf2.png'} style={{width:'20px', height:'20px'}}/>
    </button>
    <button className="circle-button" style={{marginLeft:'10px'}}><img src='./img/bf4.png' style={{width:'15px',height:'15px'}}/></button>
    <button className="circle-button" style={{marginLeft:'10px'}}><img src='./img/bf2.png' style={{width:'15px',height:'15px'}}/></button>
    <input
        type="range"
        min="0"
        max="100"
        value={progress} // 将进度状态绑定到输入值
        onChange={() => {}} // 设置为空函数，防止控制台报警告
        onMouseDown={handleMouseDown} // 当鼠标按下时开始监听鼠标移动事件
        ref={inputRef} // 将引用绑定到输入元素
        className="progress-bar"
        style={{ width: "calc(100% - 200px)", marginLeft: '20px' }} // 减去按钮的宽度
        onClick={handleClick} // 单击事件更新进度条的值
      />
      
    </div>
    </>
  );
};

export default Player;
