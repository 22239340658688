// import React from "react";
import React, { useState } from 'react';
import {Routes,Route,Link, Navigate} from "react-router-dom";


// 自定义路由组件

import Home from "./pages/Home/Home"
import Worktable from "./pages/Worktable/Worktable";
import Update from "./pages/Upload/Upload";
import Threestyle from "./pages/Threestyle/Threestyle";
import Wasm_js from "./pages/Wasm_js/Wasmjs";
import NewPage from "./pages/Threestyle/componentsthree/Three/SkipPage";


import LoginPage from './pages/UserLogin/LoginPage';
// antd ui库
import { Layout, Menu } from 'antd';
import {HomeOutlined, EditOutlined,PrinterOutlined,CameraOutlined } from "@ant-design/icons";
import { Header,Footer} from "antd/es/layout/layout";
import Print from "./pages/Print/Print";


const App = ({}) =>{

  const [isLoggedIn,setIsLoggedIn] =useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  }
  
  return (
    <>
      
      <Layout>
        {!isLoggedIn && <LoginPage onLogin ={handleLogin}/>}
        {isLoggedIn && (
          <>
          <Header style={{display: 'flex', alignItems: 'center'}}>
          <div  style={{width:'100px',height:'64px',textAlign:'center',margin: "45px"}} className="demo-logo">
            <span style={{fontSize:'14px',fontFamily:'微软雅黑',color:'white'}}>logo</span>
          </div>
          
          <Menu
          theme="dark"
          mode="horizontal"
          style={{width:'90%'}}
          items={[{
            key:'home',
            label:<Link to={'/home'} style={{color:'white'}}>首页</Link>,
            icon:<HomeOutlined/>,
          },
          {
            key:'worktable',
            label: <Link to={'/worktable'} style={{color:'white'}}>VTK.js</Link>,
            icon:<EditOutlined />
          },
          {
            key:'three',
            label:<Link to={'/three'} style={{color:'white'}}>Three.js</Link>,
            icon:<CameraOutlined/>
          },
          {
            key:'wasm',
            label:<Link to={'/wosm'} style={{color:'white'}}>Wasm.js</Link>,

          },
          {
            key:'print',
            label:<Link to={'/print'} style={{color:'white'}}>打印</Link>,
            icon:<PrinterOutlined />
          },                
          ]}>
          </Menu>
        </Header>

        <Layout>
          <Routes>
            <Route path="/" element={<Navigate to="/home"/>} />
            <Route path='/home' element={<Home/>}/>
            <Route path="/worktable" element={<Worktable/>}/>
            <Route path='/print' element={<Print/>}/>
            <Route path='/update' element={<Update/>}/>
            <Route path="/three" element={<Threestyle />}/>
            <Route path="/wasm" element={<Wasm_js/>}/>
            <Route path="/new-page" element={<NewPage/>} />
          </Routes>
      
        </Layout>
        <Footer style={{textAlign: 'center'}}>页尾</Footer>
      </>
      )}
      </Layout>
    </>    
  )
}

export default App;
