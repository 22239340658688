

// antd 组件
import { Button, Tree } from "antd";
import { useState } from "react";


// 文件目录组件
function FileDirectory(){
    const {DirectoryTree} = Tree;
    const [fileTree,setFileTree] = useState([{title:'stl文件列表',key:'0-0',children:[{
                title: '临时预览1',
                key: '0-0-0',
                isLeaf: true,
            },
            {
                title: '临时预览2',
                key: '0-0-1',
                isLeaf: true,
            },]
        }])
    //  文件列表点击事件
    const onSelect = (keys, info) => {
        
    };
    const onExpand = (keys, info) => {
        console.log('Trigger Expand', keys, info);
    };
    
    const addNode = () =>{
        console.log({...fileTree});
        const temp = [{
                ...fileTree[0],children:[{
                title: '临时预览1',
                key: '0-0-0',
                isLeaf: true,
            }]
        }];
        setFileTree(temp);
    }

    // const treeData = [
    // {
    //     title: 'stl文件列表',
    //     key: '0-0',
    //     children: [{
    //         title: '临时预览1',
    //         key: '0-0-0',
    //         isLeaf: true,
    //     },
    //     {
    //         title: '临时预览2',
    //         key: '0-0-1',
    //         isLeaf: true,
    //     },],
    //     },
    // ];


    return (
        <div>
    <DirectoryTree
        multiple
        defaultExpandAll
        onSelect={onSelect}
        onExpand={onExpand}
        treeData={fileTree}
    ></DirectoryTree>

    </div>
    )
}

export default FileDirectory;
