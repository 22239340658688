

// 首页路由组件
function Home() {
    return(
        <div>
            <h1>
            这是首页
            </h1> 
            
        </div>
    )
}

export default Home;

